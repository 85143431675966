<template>
  <Popup>
    <div class="header-content">
      <img
        @click="closePopup"
        style="cursor: pointer; margin-top: -1rem"
        width="25"
        height="25"
        src="@/assets/eliminar-50.png"
        alt="delete-sign--v1"
      />
      <h1
        style="
          text-align: center;
          margin-top: 1rem;
          margin-bottom: 3rem;
          color: white;
          font-weight: 100;
        "
      >
        {{ $t("message.planta.f31") }}
      </h1>
    </div>

    <div>
      <form class="popup-form">
        <div class="group">
          <v-select
            v-model="principalId"
            :options="principal"
            label="name"
            required
            :placeholder="$t('message.planta.f31')"
            class="custom-select"
          />
        </div>
        <div class="group">
          <v-select
            v-model="accountSelected"
            :options="accounts"
            label="countryName"
            required
            placeholder="country"
            class="custom-select"
          />
        </div>
        <div class="btn-content">
          <button class="btn-submit-address" @click="confirmarPopup">
            {{ $t("message.planta.f32") }}
          </button>
        </div>
      </form>
    </div>
  </Popup>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Popup from "../../../components/planta-conte/Popup.vue";
import "@/assets/sass/StylesPlanta.scss";
import { mapState, mapGetters } from "vuex";
import vSelect from "vue-select";
import store from "@/store/index";

interface Account {
  accountId: number;
  countryId: number;
  countryName: string;
  name: string;
  pool: number;
}

export default defineComponent({
  name: "seletedPrinciapl",
  emits: ["close", "confirm"],
  components: {
    Popup,
    vSelect,
  },
  computed: {
    ...mapState({
      coordenate: (state: any) => state.AUTH.coordenateData,
      user: (state: any) => state.AUTH.user,
      token: (state: any) => state.AUTH.token,
    }),
    ...mapGetters(["permissions"]),
  },
  data() {
    return {
      showPopup: false,
      showPopupDrow: false,
      showPopupDrowSus: false,
      showPopupDrowBaja: false,
      showPopupDrowSoporte: false,
      validAddress: false,
      accountSelected: null,
      process: false,
      principal: [],
      newData: {
        nickname: null,
        device: null,
        planservice: null,
      },
      principalId: null,
      accounts: [] as Account[],
      changeplan: [],
      suscriptores: [],
      serial: [],
    };
  },
  mounted() {
    var data = {
      endpoint: 13,
      type: "data",
    };
    this.serviceline(data);
  },
  methods: {
    accounNumber() {
      var data = {
        endpoint: 31,
        type: "data",
      };
      store
        .dispatch("SERVICELINEUSERCONFIG", data)
        .then((response) => {
          console.log(response);
          // this.accounts = response.data.data;

          // Convertimos la respuesta a un array de tipo Account
          const accounts = response.data.data as Account[];

          // Filtra para que solo se incluyan las cuentas con pool = 0
          this.accounts = accounts.filter((account: Account) => account.pool === 0);

          // this.changeplan = response.data.data.map(function (
          //   cadena: any,
          // ) {
          //   return { id: cadena.serviceplans_id, name: cadena.serviceplans_name, productReferenceId: cadena.serviceplans_productReferenceId };
          // });
          // this.showPopup = false;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    closePopup() {
      this.$emit("close");
    },
    confirmarPopup() {
      this.$emit("confirm");
      store.commit("SET_PRINCIPAL_SELECTED", this.principalId);
      store.commit("SET_ACCOUNT_SELECTED", this.accountSelected);
      this.closePopup();
    },
    serviceline(data: any) {
      console.log(data);
      store
        .dispatch("SERVICELINEUSERCONFIG", data)
        .then((response) => {
          console.log(response);
          this.principal = response.data.data;
          this.accounNumber();
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
  },
});
</script>
<style lang="scss">
.dropdown-content {
  position: fixed;
  display: none;
  width: 160px;
  height: 225px;
  background: #0066ff;
  border-radius: 10px;
  margin-top: 0px;
}

.dropdown-content:last-of-type {
  position: fixed;
  display: none;
  width: 160px;
  height: 225px;
  background: #0066ff;
  border-radius: 10px;
  margin-top: -225px;
  margin-left: 45px;
}

.dropdown-content:hover {
  position: fixed;
  display: none;
  width: 160px;
  height: 225px;
  background: #0066ff;
  border-radius: 10px;
}

.dropdown-content a:hover {
  background-color: #0079ff;
}

.btn-submit-address {
  height: 32px;
  width: 220px;
  border: none;
  border-radius: 9px;
  color: #ffffff;
  font-weight: bold;
  background-color: #0066ff;
}

.btn-submit-mapa {
  height: 32px;
  width: 220px;
  border: none;
  border-radius: 9px;
  color: #ffffff;
  font-weight: bold;
  background-color: #0066ff;
}

.custom-select .vs__dropdown-toggle {
  background-color: white;
}

.custom-input {
  background-color: white;
  border: 1px solid #ccc;
  padding: 8px;
  border-radius: 4px;
  width: 95%;
  cursor: pointer;
}

.header-content {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}
</style>
