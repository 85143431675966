<template>
  <Popup>
    <div class="header-content">
      <img @click="closePopup" style="cursor: pointer; margin-top: -1rem" width="25" height="25"
        src="@/assets/eliminar-50.png" alt="delete-sign--v1" />
      <h1 style="
          text-align: center;
          margin-top: 1rem;
          margin-bottom: 3rem;
          color: white;
          font-weight: 100;
        ">
        {{ $t("message.planta.f18") }} {{ terminalSelected.terminals_nickname }}
      </h1>
    </div>

    <div>
      <form class="popup-form" @submit.prevent="verificarAddress()">
        <div class="group">
          <input disabled v-model="terminalSelected.directions_formattedAddress" class="custom-input" type="text"
            :placeholder="$t('message.planta.c11')" style="padding-left: 0.5rem" />
        </div>
        <div class="group">
          <input disabled v-model="terminalSelected.directions_latitude" class="custom-input" type="text"
            :placeholder="$t('message.planta.f16')" style="padding-left: 0.5rem" />
        </div>
        <div class="group">
          <input disabled v-model="terminalSelected.directions_longitude" class="custom-input" type="text"
            :placeholder="$t('message.planta.f16')" style="padding-left: 0.5rem" />
        </div>
        <div class="group">
          <input required v-model="newService.direction" class="custom-input" type="text"
            :placeholder="$t('message.planta.f17')" style="padding-left: 0.5rem" />
        </div>

        <div class="btn-content">
          <button class="btn-submit-address" type="submit">
            {{
              coordenate.length > 0
                ? $t("message.planta.f3")
                : $t("message.planta.f4")
            }}
          </button>
        </div>
      </form>
    </div>

    <div style="margin: 20px 0px" v-if="validAddress">
      <Map :height="200" :width="320"></Map>
    </div>

    <form v-if="validAddress" class="popup-form" @submit.prevent="changeAddress()">
      <div v-if="newData.params" class="group">
        <input disabled v-model="newData.params.regionCode" class="custom-input" type="text"
          :placeholder="$t('message.planta.c11')" style="padding-left: 0.5rem" />
      </div>
      <div v-if="newData.params" class="group">
        <input disabled v-model="newData.params.latitude" class="custom-input" type="text"
          :placeholder="$t('message.planta.f16')" style="padding-left: 0.5rem" />
      </div>
      <div v-if="newData.params" class="group">
        <input disabled v-model="newData.params.longitude" class="custom-input" type="text"
          :placeholder="$t('message.planta.f16')" style="padding-left: 0.5rem" />
      </div>
      <div class="btn-content" v-if="!isLoadingUpdateAddress">
        <button :disabled="process" class="btn-submit" type="submit" style="margin-left: 1rem">
          <div style="
              display: flex;
              justify-content: space-evenly;
              width: 90%;
              margin: auto;
              align-items: center;
            ">
            <div>{{ $t("message.planta.f18") }}</div>
            <div>
              <img src="@/assets/Vector-servicio.png" alt="img-vector" />
            </div>
          </div>
        </button>
      </div>
      <div v-else class="spinner-content2">
        <ProgressSpinner style="width: 50px; height: 50px" strokeWidth="8" fill="transparent" animationDuration=".5s"
          aria-label="Custom ProgressSpinner" />
      </div>
    </form>
  </Popup>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Popup from "../../../components/planta-conte/Popup.vue";
import store from "../../../store";
import "@/assets/sass/StylesPlanta.scss";
import { mapState, mapGetters } from "vuex";
import Map from "../../../components/map/index.vue";
import vSelect from "vue-select";

// Define la interfaz aquí
interface StepDetail {
  status: string;
  step: string;
  [key: string]: any;
}
// PRUEBA NUEVO CHANGEADDRESS
// interface Step {
//   status: string;
//   request: object;
//   response: object;
//   step: string;
//   timestamp: string;
// }

interface ErrorDetails {
  code: number;
  data: any[];
  isValid: boolean;
  message: string;
}

interface Result {
  code: number;
  data?: any;
  message?: string;
  error?: ErrorDetails | string;
}

export default defineComponent({
  name: "updateService",
  props: ["terminalSelected"],
  emits: ["close"],
  components: {
    Popup,
    Map,
    vSelect,
  },
  computed: {
    ...mapState({
      coordenate: (state: any) => state.AUTH.coordenateData,
      user: (state: any) => state.AUTH.user,
      token: (state: any) => state.AUTH.token,
    }),
    ...mapGetters(["permissions"]),
  },
  data() {
    return {
      isLoadingUpdateAddress: false,
      validAddress: false,
      process: false,
      // newService: {
      //   direction: "",
      //   addressLines: "",
      //   locality: "",
      //   administrativeArea: "", // mayuscula
      //   administrativeAreaCode: "", // mayuscula
      //   region: "",
      //   regionCode: "", // mayuscula
      //   postalCode: "",
      //   latitude: "",
      //   longitude: "",
      //   formattedAddress: "",
      //   productReferenceId: "",
      // },
      newService: {
        nickname: "",
        planservice: "",
        direction: "",
        kitSerialNumber: "",
        suscriptor: "",
        addressLines: "",
        locality: "",
        administrativeArea: "", // mayuscula
        administrativeAreaCode: "", // mayuscula
        region: "",
        regionCode: "", // mayuscula
        postalCode: "",
        latitude: "",
        longitude: "",
        formattedAddress: "",
        productReferenceId: "",
      },
      newData: {},
    };
  },
  mounted() { },
  methods: {
    serviceline(data: any) {
      store
        .dispatch("SERVICELINEUSERCONFIG", data)
        .then((response) => {
          store.dispatch("showToast", {
            message: "LISTADO DE PLANTA",
            type: "success",
          });
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    closePopup() {
      this.$emit("close");
    },

    // verificarAddress() {
    //   console.log("coordenate", this.coordenate)
    //   if (this.permissions.some((evt: any) => evt.id === 7)) {
    //     var dataValid = {
    //       endpoint: 18,
    //       type: "api",
    //       requestBody: {
    //         $address: this.newService.direction
    //       }
    //     }
    //     store.dispatch("VERIFYADDRESS", dataValid).then((response2) => {
    //       console.log("VERIFYADDRESS", response2);
    //       if (response2.data.code === 200) {
    //         console.log('status 200');
    //         this.validAddress = true;
    //         if (response2.data.data.results.length > 0) {
    //           console.log('hau resultados');
    //           var dataValue = response2.data.data.results[0];
    //           console.log("dataValue", dataValue)
    //           var country = {
    //             long_name: null,
    //             short_name: null
    //           };
    //           var postalCodeRef = {
    //             long_name: null,
    //             short_name: null
    //           };
    //           var localCodeRef = {
    //             long_name: null,
    //             short_name: null
    //           };
    //           dataValue.results[0].address_components.forEach((component: any) => {
    //             component.types.forEach((evt: any) => {
    //               if (evt === "country") {
    //                 country = component;
    //               }
    //               if (evt === "postal_code") {
    //                 postalCodeRef = component;
    //               }
    //               if (evt === "locality") {
    //                 localCodeRef = component;
    //               }
    //             })
    //           });
    //           var dataValid = {
    //             endpoint: 24,
    //             type: "api",
    //             requestBody: {
    //               nickname: null,
    //               deviceId: null,
    //               serviceplanId: null,
    //               directionId: this.terminalSelected.directions_id,
    //               accountNumber: this.terminalSelected.accountNumber,
    //               principalId: this.terminalSelected.principalId,
    //               platformId: process.env.VUE_APP_PLATFORM,
    //               addressLines: response2.data.data.results[0].results[0].formatted_address,
    //               locality: localCodeRef ? localCodeRef.long_name : "",
    //               administrativeArea: country ? country.short_name : "", // mayuscula
    //               administrativeAreaCode: country ? country.short_name : "", // mayuscula
    //               region: country ? country.long_name : "",
    //               regionCode: country ? country.short_name : "", // mayuscula
    //               latitude: response2.data.data.results[0].results[0].geometry.location.lat,
    //               longitude: response2.data.data.results[0].results[0].geometry.location.lng,
    //               formattedAddress: response2.data.data.results[0].results[0].formatted_address,
    //               postalCode: postalCodeRef ? postalCodeRef.long_name : "",
    //             }
    //           }
    //           console.log("dataValid", dataValid)
    //           store.commit("SET_COORDENATE", dataValid);
    //           console.log('ok');
    //           this.validAddress = true;
    //         } else {
    //           store.dispatch("showToast", {
    //             message: "NO ENCONTRO DIRECCIÓN",
    //             type: "error",
    //           });
    //         }
    //         store.dispatch("showToast", {
    //           message: "VERIFICADO",
    //           type: "success",
    //         });
    //       } else {
    //         this.validAddress = false;
    //         store.dispatch("showToast", {
    //           message: response2.data.message[0],
    //           type: "error",
    //         });
    //       }
    //       console.log(response2);
    //     }).catch((error) => {
    //       this.validAddress = false;
    //       store.dispatch("showToast", {
    //         message: String(error.response.data.message[0]),
    //         type: "error",
    //       });
    //     });
    //   } else {
    //     this.process = false;
    //     store.dispatch("showToast", {
    //       message: "NO TIENES PERMISOS PARA ESTA ACCION",
    //       type: "error",
    //     });
    //   }
    // },





    // ///////////////////////////////

    // changeAddress() {
    //   store.dispatch("UPDATEADDRESSREFERENCEDB", this.coordenate)
    //     .then((response) => {
    //       if (response.data.data.results.length > 1) {
    //         if (response.data.data.results[1].code === 200) {
    //           store.dispatch("showToast", {
    //             message: "Cambio realizado exitosamente",
    //             type: "success",
    //           });
    //           this.$emit("close");
    //         } else {
    //           store.dispatch("showToast", {
    //             message: "No se pudo realizar el cambio de dirección",
    //             type: "error",
    //           });
    //         }

    //       } else {
    //         if (response.data.data.results[0].code === 200) {
    //           store.dispatch("showToast", {
    //             message: "Cambio realizado exitosamente",
    //             type: "success",
    //           });
    //           this.$emit("close");
    //         }
    //       }

    //     })
    //     .catch((error) => {
    //       this.validAddress = false;
    //       this.$emit("close");
    //       store.dispatch("showToast", {
    //         message: "Hubo un error al intentar cambiar la dirección",
    //         type: "error",
    //       });
    //     });
    // },



    // async verificarAddress() {
    //   if (this.permissions.some((evt: any) => evt.id === 7)) {
    //     const inputValue = this.newService.direction.trim();

    //     // 📌 Verificar si es un par de coordenadas "lat,long"
    //     const isCoordinates = /^-?\d+(\.\d+)?,-?\d+(\.\d+)?$/.test(inputValue);

    //     // 📌 Verificar si es un Plus Code
    //     const isPlusCode = /^[23456789CFGHJMPQRVWX]{4,6}\+[23456789CFGHJMPQRVWX]{2,3}(,\s*\w+.*)?$/i.test(inputValue);

    //     let dataValid;

    //     if (isCoordinates) {
    //       // 📌 Usuario ingresó coordenadas directamente
    //       console.log("Detectado formato de coordenadas:", inputValue);

    //       const [latitude, longitude] = inputValue.split(",").map(coord => parseFloat(coord.trim()));

    //       if (isNaN(latitude) || isNaN(longitude)) {
    //         store.dispatch("showToast", {
    //           message: "Coordenadas inválidas. Usa formato: lat,long",
    //           type: "error",
    //         });
    //         return;
    //       }

    //       try {
    //         // 📌 Llamar a la API de geocodificación inversa de OpenStreetMap (Nominatim)
    //         const response = await fetch(
    //           `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`
    //         );
    //         const geoData = await response.json();

    //         // 📌 Extraer datos de la API si están disponibles
    //         const locality = geoData.address?.city || geoData.address?.town || geoData.address?.village || "Desconocido";
    //         const administrativeArea = geoData.address?.state || "Desconocido";
    //         const administrativeAreaCode = geoData.address?.state_code || "Desconocido";
    //         const region = geoData.address?.country || "Desconocido";
    //         const regionCode = geoData.address?.country_code?.toUpperCase() || "Desconocido";
    //         const postalCode = geoData.address?.postcode || "00000";
    //         const formattedAddress = geoData.display_name || `Lat: ${latitude}, Long: ${longitude}`;

    //         // 📌 Crear `dataValid` con los valores obtenidos
    //         dataValid = {
    //           endpoint: 24,
    //           type: "api",
    //           requestBody: {
    //             nickname: null,
    //             deviceId: null,
    //             serviceplanId: null,
    //             directionId: this.terminalSelected.directions_id || "N/A",
    //             accountNumber: this.terminalSelected.accountNumber || "N/A",
    //             principalId: this.terminalSelected.principalId || "N/A",
    //             platformId: process.env.VUE_APP_PLATFORM || "N/A",
    //             addressLines: formattedAddress,
    //             locality,
    //             administrativeArea,
    //             administrativeAreaCode,
    //             region,
    //             regionCode,
    //             latitude,
    //             longitude,
    //             formattedAddress,
    //             postalCode,
    //           },
    //         };

    //         store.commit("SET_COORDENATE", dataValid);
    //         this.validAddress = true;
    //         store.dispatch("showToast", {
    //           message: "Coordenadas procesadas correctamente",
    //           type: "success",
    //         });

    //       } catch (error) {
    //         console.error("Error en Geocodificación Inversa:", error);
    //         store.dispatch("showToast", {
    //           message: "Error obteniendo datos de la ubicación",
    //           type: "error",
    //         });
    //       }

    //     } else if (isPlusCode) {
    //       // 📌 Usuario ingresó un Plus Code
    //       try {
    //         console.log("Detectado Plus Code:", inputValue);

    //         // 📌 Llamar a `VERIFYADDRESS` para resolver el Plus Code
    //         dataValid = {
    //           endpoint: 18,
    //           type: "api",
    //           requestBody: {
    //             $address: inputValue,
    //           },
    //         };

    //         store.dispatch("VERIFYADDRESS", dataValid).then((response2) => {
    //           if (
    //             response2.data.code === 200 &&
    //             response2.data.data.results.length > 0 &&
    //             response2.data.data.results[0].results.length > 0
    //           ) {
    //             const dataValue = response2.data.data.results[0].results[0];

    //             // 📌 Verificar que `geometry.location` exista antes de acceder a él
    //             if (!dataValue.geometry || !dataValue.geometry.location) {
    //               console.error("No se encontró 'location' en la respuesta", dataValue);
    //               throw new Error("No se pudieron obtener coordenadas");
    //             }

    //             const location = dataValue.geometry.location;

    //             dataValid = {
    //               endpoint: 24,
    //               type: "api",
    //               requestBody: {
    //                 nickname: null,
    //                 deviceId: null,
    //                 serviceplanId: null,
    //                 directionId: this.terminalSelected.directions_id || "N/A",
    //                 accountNumber: this.terminalSelected.accountNumber || "N/A",
    //                 principalId: this.terminalSelected.principalId || "N/A",
    //                 platformId: process.env.VUE_APP_PLATFORM || "N/A",
    //                 addressLines: dataValue.formatted_address,
    //                 locality: dataValue.address_components?.find((c: { types: string[]; long_name?: string }) =>
    //                   c.types.includes("locality")
    //                 )?.long_name || "Desconocido",
    //                 administrativeArea: dataValue.address_components?.find((c: { types: string[]; long_name?: string }) =>
    //                   c.types.includes("administrative_area_level_1")
    //                 )?.long_name || "Desconocido",
    //                 administrativeAreaCode: dataValue.address_components?.find((c: { types: string[]; short_name?: string }) =>
    //                   c.types.includes("administrative_area_level_1")
    //                 )?.short_name || "Desconocido",
    //                 region: dataValue.address_components?.find((c: { types: string[]; long_name?: string }) =>
    //                   c.types.includes("country")
    //                 )?.long_name || "Desconocido",
    //                 regionCode: dataValue.address_components?.find((c: { types: string[]; short_name?: string }) =>
    //                   c.types.includes("country")
    //                 )?.short_name || "Desconocido",
    //                 latitude: location.lat,
    //                 longitude: location.lng,
    //                 formattedAddress: dataValue.formatted_address,
    //                 postalCode: dataValue.address_components?.find((c: { types: string[]; long_name?: string }) =>
    //                   c.types.includes("postal_code")
    //                 )?.long_name || "00000",
    //               },
    //             };


    //             store.commit("SET_COORDENATE", dataValid);
    //             this.validAddress = true;
    //             store.dispatch("showToast", {
    //               message: "Plus Code convertido con éxito",
    //               type: "success",
    //             });
    //           }
    //         });

    //       } catch (error) {
    //         console.error("Error al procesar el Plus Code:", error);
    //         store.dispatch("showToast", {
    //           message: "Error al convertir Plus Code",
    //           type: "error",
    //         });
    //       }

    //     } else {
    //       // 📌 Usuario ingresó una dirección normal
    //       dataValid = {
    //         endpoint: 18,
    //         type: "api",
    //         requestBody: {
    //           $address: inputValue,
    //         },
    //       };

    //       store
    //         .dispatch("VERIFYADDRESS", dataValid)
    //         .then((response2) => {
    //           if (
    //             response2.data.code === 200 &&
    //             response2.data.data.results.length > 0 &&
    //             response2.data.data.results[0].results.length > 0
    //           ) {
    //             const dataValue = response2.data.data.results[0].results[0];

    //             if (!dataValue.geometry || !dataValue.geometry.location) {
    //               console.error("No se encontró 'location' en la respuesta", dataValue);
    //               throw new Error("No se pudieron obtener coordenadas");
    //             }

    //             const location = dataValue.geometry.location;

    //             dataValid = {
    //               endpoint: 24,
    //               type: "api",
    //               requestBody: {
    //                 addressLines: dataValue.formatted_address,
    //                 latitude: location.lat,
    //                 longitude: location.lng,
    //                 formattedAddress: dataValue.formatted_address,
    //               },
    //             };

    //             store.commit("SET_COORDENATE", dataValid);
    //             this.validAddress = true;
    //           }
    //         });
    //     }
    //   }
    // },



    async verificarAddress() {
      const inputValue = this.newService.direction.trim();
      // Verifica si la entrada es un par de coordenadas en formato "lat, lng"
      const isCoordinates = /^-?\d+(\.\d+)?\s*,\s*-?\d+(\.\d+)?$/.test(inputValue);

      if (isCoordinates) {
        const [lat, lng] = inputValue.split(",").map(coord => parseFloat(coord.trim()));
        try {
          // Llamada a geocodificación inversa usando Nominatim
          const response = await fetch(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lng}`);
          if (!response.ok) {
            throw new Error("Error al obtener datos geográficos");
          }
          const geoData = await response.json();
          // Extraer datos relevantes de la respuesta
          const locality = geoData.address.city ||
            geoData.address.town ||
            geoData.address.village || "Desconocido";
          const administrativeArea = geoData.address.state || "Desconocido";
          // Nominatim puede no proveer un código de estado, por lo que se puede dejar vacío o derivado
          const administrativeAreaCode = geoData.address.state_code ? geoData.address.state_code.toUpperCase() : "XX";
          const country = geoData.address.country || "Desconocido";
          const countryCode = geoData.address.country_code ? geoData.address.country_code.toUpperCase() : "XX";

          const dataValid = {
            endpoint: 24,
            type: "api",
            requestBody: {
              nickname: null,
              deviceId: null,
              serviceplanId: null,
              directionId: this.terminalSelected.directions_id,
              accountNumber: this.terminalSelected.accountNumber,
              principalId: this.terminalSelected.principalId,
              platformId: process.env.VUE_APP_PLATFORM,
              addressLines: `Ubicación: ${lat}, ${lng}`,
              locality: locality,
              administrativeArea: administrativeArea,
              administrativeAreaCode: administrativeAreaCode,
              region: country,
              regionCode: countryCode,
              latitude: lat,
              longitude: lng,
              formattedAddress: `Ubicación: ${lat}, ${lng}`,
              postalCode: "null"
            }
          };
          store.commit("SET_COORDENATE", dataValid);
          this.validAddress = true;
          store.dispatch("showToast", {
            message: "Coordenadas procesadas correctamente",
            type: "success",
          });
        } catch (error) {
          console.error("Error en reverse geocoding:", error);
          store.dispatch("showToast", {
            message: "Error obteniendo datos de la ubicación",
            type: "error",
          });
          this.validAddress = false;
        }
      } else if (this.permissions.some((evt: any) => evt.id === 7)) {
        // Si no es formato de coordenadas, se utiliza la verificación normal (VERIFYADDRESS)
        const dataValid = {
          endpoint: 18,
          type: "api",
          requestBody: { $address: inputValue }
        };
        store.dispatch("VERIFYADDRESS", dataValid)
          .then((response2) => {
            console.log("VERIFYADDRESS", response2);
            if (response2.data.code === 200) {
              this.validAddress = true;
              if (response2.data.data.results.length > 0) {
                const dataValue = response2.data.data.results[0];
                let country = { long_name: null, short_name: null };
                let postalCodeRef = { long_name: null, short_name: null };
                let localCodeRef = { long_name: null, short_name: null };
                dataValue.results[0].address_components.forEach((component: any) => {
                  component.types.forEach((evt: any) => {
                    if (evt === "country") {
                      country = component;
                    }
                    if (evt === "postal_code") {
                      postalCodeRef = component;
                    }
                    if (evt === "locality") {
                      localCodeRef = component;
                    }
                  });
                });
                const newDataValid = {
                  endpoint: 24,
                  type: "api",
                  requestBody: {
                    nickname: null,
                    deviceId: null,
                    serviceplanId: null,
                    directionId: this.terminalSelected.directions_id,
                    accountNumber: this.terminalSelected.accountNumber,
                    principalId: this.terminalSelected.principalId,
                    platformId: process.env.VUE_APP_PLATFORM,
                    addressLines: response2.data.data.results[0].results[0].formatted_address,
                    locality: localCodeRef ? localCodeRef.long_name : "",
                    administrativeArea: country ? country.short_name : "",
                    administrativeAreaCode: country ? country.short_name : "",
                    region: country ? country.long_name : "",
                    regionCode: country ? country.short_name : "",
                    latitude: response2.data.data.results[0].results[0].geometry.location.lat,
                    longitude: response2.data.data.results[0].results[0].geometry.location.lng,
                    formattedAddress: response2.data.data.results[0].results[0].formatted_address,
                    postalCode: postalCodeRef ? postalCodeRef.long_name : "",
                  }
                };
                store.commit("SET_COORDENATE", newDataValid);
                store.dispatch("showToast", {
                  message: "VERIFICADO",
                  type: "success",
                });
              } else {
                store.dispatch("showToast", {
                  message: "NO ENCONTRO DIRECCIÓN",
                  type: "error",
                });
              }
            } else {
              this.validAddress = false;
              store.dispatch("showToast", {
                message: response2.data.message[0],
                type: "error",
              });
            }
          })
          .catch((error) => {
            this.validAddress = false;
            store.dispatch("showToast", {
              message: String(error.response.data.message[0]),
              type: "error",
            });
          });
      } else {
        this.process = false;
        store.dispatch("showToast", {
          message: "NO TIENES PERMISOS PARA ESTA ACCION",
          type: "error",
        });
      }
    },





    changeAddress() {
      this.isLoadingUpdateAddress = true;

      // Construir el payload con el formato esperado por el backend 
      const payload = {
        ...this.coordenate.requestBody,
        postalCode: this.coordenate.requestBody.postalCode || "null", // Asignar "null" como string si no está presente
      };

      console.log("Payload enviado a UPDATEADDRESSREFERENCEDB:", payload);

      // Validar que los campos obligatorios estén presentes
      const requiredFields = [
        "accountNumber",
        "directionId",
        "addressLines",
        "locality",
        "administrativeArea",
        "administrativeAreaCode",
        "region",
        "regionCode",
        "formattedAddress",
        "latitude",
        "longitude",
      ];

      const missingFields = requiredFields.filter((field) => !payload[field]);
      if (missingFields.length > 0) {
        console.error(`Faltan los campos obligatorios: ${missingFields.join(", ")}`);
        store.dispatch("showToast", {
          message: `Faltan los campos obligatorios: ${missingFields.join(", ")}`,
          type: "error",
        });
        this.isLoadingUpdateAddress = false;
        return;
      }

      // Llamar a la API para actualizar la dirección
      store
        .dispatch("UPDATEADDRESSREFERENCEDB", payload)
        .then((response) => {
          console.log("Respuesta completa de UPDATEADDRESSREFERENCEDB:", response);

          if (response.status === 200 && response.data?.isValid) {
            const details: StepDetail[] = response.data?.details || [];
            console.log("Detalles recibidos:", details);

            // Validar si todos los pasos del proceso son exitosos
            const allStepsSuccess = details.every((step: StepDetail) => step.status === "success");
            if (allStepsSuccess) {
              store.dispatch("showToast", {
                message: "Cambio realizado exitosamente",
                type: "success",
              });
              this.$emit("close");
            } else {
              const failedSteps = details.filter((step: StepDetail) => step.status !== "success");
              console.error("Pasos fallidos:", failedSteps);

              store.dispatch("showToast", {
                message: "Error en algunos pasos del proceso de cambio de dirección",
                type: "error",
              });
            }
          } else {
            console.error("Error en la respuesta de la API:", response.data);
            throw new Error(response.data?.message || "Error al realizar la solicitud");
          }
        })
        .catch((error) => {
          console.error("Error en UPDATEADDRESSREFERENCEDB:", error);
          store.dispatch("showToast", {
            message: error.response?.data?.message || "Error al cambiar la dirección",
            type: "error",
          });
        })
        .finally(() => {
          this.isLoadingUpdateAddress = false;
        });

    }


  },
});
</script>
<style lang="scss">
.dropdown-content {
  position: fixed;
  display: none;
  width: 160px;
  height: 225px;
  background: #0066ff;
  border-radius: 10px;
  margin-top: 0px;
}

.dropdown-content:last-of-type {
  position: fixed;
  display: none;
  width: 160px;
  height: 225px;
  background: #0066ff;
  border-radius: 10px;
  margin-top: -225px;
  margin-left: 45px;
}

.dropdown-content:hover {
  position: fixed;
  display: none;
  width: 160px;
  height: 225px;
  background: #0066ff;
  border-radius: 10px;
}

.dropdown-content a:hover {
  background-color: #0079ff;
}

.btn-submit-address {
  height: 32px;
  width: 220px;
  border: none;
  border-radius: 9px;
  color: #ffffff;
  font-weight: bold;
  background-color: #0066ff;
}

.btn-submit-mapa {
  height: 32px;
  width: 220px;
  border: none;
  border-radius: 9px;
  color: #ffffff;
  font-weight: bold;
  background-color: #0066ff;
}

.custom-select .vs__dropdown-toggle {
  background-color: white;
}

.custom-input {
  background-color: white;
  border: 1px solid #ccc;
  padding: 8px;
  border-radius: 4px;
  width: 95%;
  cursor: pointer;
}

.header-content {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}

.spinner-content2 {
  display: flex;
  justify-content: center;
  align-items: center;
  // min-width: 1300px;
  // min-height: 600px;
}
</style>
