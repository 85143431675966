<template>
  <!--Comisionamiento-->
  <PopupComi style="z-index: 5" v-if="showPopupDrowComisionamiento && terminalSelected">
    <div class="header-close">
      <img
        style="cursor: pointer"
        width="25"
        height="25"
        src="@/assets/eliminar-50.png"
        alt="delete-sign--v1"
        @click="closePopup"
      />
    </div>
    <div class="header-content">
      <h2 style="color: white; text-align: center">
        {{ $t("message.planta.f9") }} {{ terminalSelected.terminals_nickname }}
      </h2>
    </div>
    <form
      v-if="permissions.some((evt: any) => evt.id === 12)"
      class="popup-form"
      @submit.prevent="cargarArchivo"
    >
      <div class="file-upload-container">
        <input
          @change="handleFileChange"
          type="file"
          id="file-upload"
          class="file-upload-input"
          ref="fileInput"
          name="file"
        />

        <label for="file-upload" class="file-upload-label">{{
          $t("message.planta.f10")
        }}</label>
        <div class="btn-content">
          <button
            type="submit"
            class="btn-submit"
            id="btn-cerrar-popup2"
            style="margin-top: 0rem; margin-left: 1rem; margin-bottom: 2rem"
          >
            <strong>{{ $t("message.planta.f11") }}</strong>
          </button>
        </div>

        <div class="table-container">
          <DataTable
            :value="commisioning"
            class="table-upload-file"
            style="width: 100%; height: 100%"
          >
            <Column field="id" header="ID"></Column>
            <Column field="terminalId" header="terminalId"></Column>
            <Column field="name" header="Name" class="name-column"> </Column>
            <Column field="url" header="URL">
              <template #body="{ data }">
                <a @click.prevent="downloadFile(data)" class="download-link">Descargar</a>
              </template>
            </Column>
          </DataTable>
        </div>
      </div>
    </form>
  </PopupComi>

  <!--Baja total-->
  <PopupDrop style="z-index: 5" v-if="showPopupDrow && terminalSelected">
    <div class="header-close">
      <img
        style="cursor: pointer; margin-top: -1rem"
        width="25"
        height="25"
        src="@/assets/eliminar-50.png"
        alt="delete-sign--v1"
        @click="closePopup"
      />
    </div>
    <div class="header-content">
      <h2 style="color: white">
        {{ $t("message.planta.f12") }}
        {{ String(terminalSelected.terminals_nickname).toUpperCase() }}
      </h2>
    </div>
    <form class="popup-form" action="">
      <div class="group">
        <v-select
          v-model="newService.planservice"
          :options="myPlansSelected"
          label="nickname"
          :placeholder="$t('message.planta.f13')"
          class="custom-select"
        />
      </div>

      <div class="btn-content" v-if="!isLoadingUpdateServicePlan">
        <button
          @click.prevent="chagePlanService(terminalSelected, newService)"
          class="btn-submit"
          type="submit"
          id="btn-cerrar-popup2"
          style="margin-top: 1rem; margin-left: 1rem"
        >
          <strong>{{ $t("message.planta.f14") }}</strong>
        </button>
      </div>
      <div v-else class="spinner-content2">
        <ProgressSpinner
          style="width: 50px; height: 50px"
          strokeWidth="8"
          fill="transparent"
          animationDuration=".5s"
          aria-label="Custom ProgressSpinner"
        />
      </div>
    </form>
  </PopupDrop>

  <!--Suspender-->
  <PopupDrop style="z-index: 5" v-if="showPopupDrowSus && terminalSelected">
    <div class="header-close">
      <img
        style="cursor: pointer; margin-top: -1rem"
        width="25"
        height="25"
        src="@/assets/eliminar-50.png"
        alt="delete-sign--v1"
        @click="closePopup"
      />
    </div>
    <div class="header-content">
      <h1 style="color: white">{{ $t("message.planta.f28") }}</h1>
    </div>
    <form class="popup-form">
      <div class="group">
        <h3 style="color: white; line-height: 25px">
          {{ $t("message.planta.f29") }}
          {{ terminalSelected.terminals_nickname.toUpperCase() }} ?
        </h3>
      </div>

      <div class="btn-content">
        <button
          @click.prevent="suspender(terminalSelected)"
          class="btn-submit"
          type="submit"
          id="btn-cerrar-popup2"
          style="margin-left: 1rem"
        >
          <strong>{{ $t("message.planta.f30") }}</strong>
        </button>
      </div>
    </form>
  </PopupDrop>

  <!--Baja total-->
  <PopupDrop style="z-index: 5" v-if="showPopupDrowBaja && terminalSelected">
    <div class="header-close">
      <img
        style="cursor: pointer; margin-top: -1rem"
        width="25"
        height="25"
        src="@/assets/eliminar-50.png"
        alt="delete-sign--v1"
        @click="closePopup"
      />
    </div>
    <div class="header-content">
      <h1 style="color: white">{{ $t("message.planta.f19") }}</h1>
    </div>
    <form class="popup-form" action="" @submit="confirmSubmitBaja">
      <div class="group">
        <h3 style="color: white; line-height: 25px">
          {{ $t("message.planta.f20") }}
          {{ terminalSelected.terminals_nickname.toUpperCase() }} ?
        </h3>
      </div>

      <div class="btn-content">
        <button
          @click.prevent="bajaTotal(terminalSelected, user)"
          class="btn-submit"
          type="submit"
          id="btn-cerrar-popup2"
          style="margin-left: 1rem"
        >
          <strong>{{ $t("message.planta.f21") }}2222</strong>
        </button>
      </div>
    </form>
  </PopupDrop>

  <!--Soporte-->
  <Popup style="z-index: 5" v-if="showPopupDrowSoporte && terminalSelected">
    <div class="header-close">
      <img
        style="cursor: pointer; margin-top: -1rem"
        width="25"
        height="25"
        src="@/assets/eliminar-50.png"
        alt="delete-sign--v1"
        @click="closePopup"
      />
    </div>
    <div class="header-content">
      <h2 style="color: white">
        {{ $t("message.planta.f22") }} {{ terminalSelected.nickname }}
      </h2>
    </div>

    <form
      class="popup-form"
      @submit.prevent="generarTicket(ticketSoporte, terminalSelected)"
    >
      <div class="group">
        <v-select
          v-model="ticketSoporte.typeContact"
          :options="problemtypes.dataTypeContactId"
          label="name"
          :placeholder="$t('message.planta.m04')"
          class="custom-select"
        />
      </div>

      <div class="group">
        <input
          v-model="ticketSoporte.dataTypeContact"
          class="custom-input"
          type="text"
          :placeholder="$t('message.planta.m05')"
        />
      </div>

      <div class="group">
        <v-select
          v-model="ticketSoporte.mediaComunication"
          :options="problemtypes.dataMediaComunicationId"
          label="name"
          :placeholder="$t('message.planta.m06')"
          class="custom-select"
        />
      </div>

      <div class="group">
        <v-select
          v-model="ticketSoporte.request"
          :options="problemtypes.dataPinProblem"
          label="name"
          :placeholder="$t('message.planta.m07')"
          class="custom-select"
        />
      </div>

      <div class="group">
        <textarea
          v-model="ticketSoporte.descripcion"
          class="custom-input"
          type=""
          :placeholder="$t('message.planta.f26')"
          style="padding-left: 0.5rem; width: 318px; height: 87px"
          name=""
          id=""
          cols="30"
          rows="10"
        ></textarea>
      </div>

      <div class="btn-content">
        <button
          class="btn-submit"
          type="submit"
          id="btn-cerrar-popup2"
          style="margin-top: 1rem; margin-left: 1rem"
        >
          <strong>{{ $t("message.planta.f27") }}</strong>
        </button>
      </div>
    </form>
  </Popup>

  <!--PrincipalSelected-->
  <selectedPrincipal
    style="z-index: 5"
    v-if="showPopupPrincipal"
    @close="closePopup()"
    @confirm="confirmPopupPrincipal()"
  ></selectedPrincipal>

  <!--Nuevo Servicio-->
  <newPlant style="z-index: 5" v-if="showPopup" @close="closePopup()"></newPlant>

  <!--Cambio de direccion-->
  <ChangeAdrees
    style="z-index: 5"
    v-if="showPopupAddress"
    :terminalSelected="terminalSelected"
    @close="closePopup()"
  >
  </ChangeAdrees>

  <!--Cambio de siteID-->
  <CambiarSiteID
    style="z-index: 5"
    v-if="showPopupCambiarSiteid"
    :terminalSelected="terminalSelected"
    @close="closePopup()"
  ></CambiarSiteID>

  <!--Reactivar Servicio-->
  <ReactivarServicio
    style="z-index: 5"
    v-if="showPopupReactivar"
    :terminalSelected="terminalSelected"
    @close="closePopup()"
  ></ReactivarServicio>

  <!-- Cambiar a modo Bypass -->
  <ActivacionBypass
    style="z-index: 5"
    v-if="showPopupCambiarBypass"
    :terminalSelected="terminalSelected"
    @close="closePopup()"
  ></ActivacionBypass>

  <!-- Reinicio Remoto -->
  <ReinicioRemoto
    style="z-index: 5"
    v-if="showPopupReinicioRemoto"
    :terminalSelected="terminalSelected"
    @close="closePopup()"
  ></ReinicioRemoto>

  <!-- Agregar KIT al servicio -->
  <AgregarKit
    style="z-index: 5"
    v-if="showPopupAgregarKit"
    :terminalSelected="terminalSelected"
    @close="closePopup()"
  >
  </AgregarKit>

  <!-- Cambiar KIT al servicio -->
  <CambiarKit
    style="z-index: 5"
    v-if="showPopupCambiarKit"
    :terminalSelected="terminalSelected"
    @close="closePopup()"
  >
  </CambiarKit>

  <!-- Reasignacion de Principal -->
  <ReasignacionPrincipal
    style="z-index: 5"
    v-if="showPopupReasignacionPrincipal"
    :terminalSelected="terminalSelected"
    @close="closePopup()"
  ></ReasignacionPrincipal>

  <!-- Suspender servicio por horario -->
  <SuspenderServicioHorario
    style="z-index: 5"
    v-if="showPopupServicioHorario"
    :terminalSelected="terminalSelected"
    @close="closePopup()"
  ></SuspenderServicioHorario>

  <!-- DNS personalizado -->
  <DnsPersonalizado
    style="z-index: 5"
    v-if="showPopupDNS"
    :terminalSelected="terminalSelected"
    @close="closePopup()"
  >
  </DnsPersonalizado>

  <!-- Renombrar red wifi -->
  <RenombrarRedWifi
    style="z-index: 5"
    v-if="showPopupRenombrarWifi"
    :terminalSelected="terminalSelected"
    @close="closePopup()"
  ></RenombrarRedWifi>

  <!-- Renombrar red wifi -->
  <CambiarPasswordWifi
    style="z-index: 5"
    v-if="showPopupCambiarPasswordWifi"
    :terminalSelected="terminalSelected"
    @close="closePopup()"
  ></CambiarPasswordWifi>

  <!-- Activar datos priority adicionales -->
  <ActivarDatosPrioritarios
    style="z-index: 5"
    v-if="showPopupActivarDatosPriory"
    :terminalSelected="terminalSelected"
    @close="closePopup()"
  ></ActivarDatosPrioritarios>

  <!-- Desactivar datos priority adicionales -->
  <DesactivarDatosPrioritarios
    style="z-index: 5"
    v-if="showPopupDesactivarDatosPriory"
    :terminalSelected="terminalSelected"
    @close="closePopup()"
  ></DesactivarDatosPrioritarios>

  <!-- Reiniciar Terminal -->
  <ReiniciarTerminal
    style="z-index: 5"
    v-if="showPopupReiniciarTerminal"
    :terminalSelected="terminalSelected"
    @close="closePopup()"
  ></ReiniciarTerminal>

  <!-- Perfil Tabla -->
  <PerfilTabla style="z-index: 5" v-if="showPopupPerfilTabla" @close="closePopup()">
  </PerfilTabla>

  <!--Baja Validacion-->
  <BajaValidation
    style="z-index: 5"
    v-if="showPopupBajaValidation"
    :terminalSelected="terminalSelected"
    @close="closePopup()"
  ></BajaValidation>

  <div style="z-index: 1" class="table-content">
    <div class="slider-container">
      <div ref="slider" class="slider" @animationiteration="handleAnimationIteration">
        <div class="message success" v-for="(column, index) in slider" :key="index">
          <Message severity="warn" :closable="false">
            <span :style="{ color: 'blue', marginRight: '10px', fontSize: '13px' }"
              >SITE ID: {{ column.terminals_nickname }}</span
            >
            <span :style="{ color: 'red', marginRight: '10px', fontSize: '13px' }">
              {{ $t("message.planta.m01") + column.porcentaje + "%" }}</span
            >
            <span :style="{ color: 'blue', fontSize: '13px' }">{{
              column.serviceplans_name
            }}</span>
          </Message>
        </div>
      </div>
    </div>
    <div class="buscar-content">
      <div
        v-if="permissions.some((evt: any) => evt.id === 4)"
        style="margin-left: 0px !important; cursor: pointer"
        class="nuevo-servicio"
        @click="showPopupPrincipal = true"
      >
        <div>{{ $t("message.home.btn2") }}</div>
        <div>
          <img src="@/assets/Vector-servicio.png" alt="img-vector" />
        </div>
      </div>
      <div style="margin-right: 0px !important" class="buscar-servicio">
        <input
          v-model="query"
          class="buscador"
          type="text"
          :placeholder="$t('message.planta.search')"
          name=""
          id=""
          style="font-size: 16px"
        />
        <button class="circle">
          <img src="@/assets/Vector-search.png" alt="img-search" />
        </button>
        <div class="btn-content">
          <button @click="exportCSV($event)">{{ $t("message.export") }}</button>
        </div>

        <div>
          <button
            v-if="permissions.some((evt: any) => evt.id === 168)"
            @click="showPopupPerfilTabla = true"
            style="
              background-color: azure;
              border-radius: 10px;
              height: 42.3px;
              cursor: pointer;
            "
          >
            <img src="@/assets/campana.gif" alt="campana" />
          </button>
        </div>
      </div>
    </div>
    <DataTable
      v-if="!isLoading"
      :value="filteredTerminals"
      paginator
      :rows="10"
      ref="dt"
      :rowsPerPageOptions="[5, 10, 15]"
      stripedRows
      tableStyle="min-width: 1300px"
    >
      <Column
        class="title-c"
        v-for="(column, index) in myColumnTablet"
        :key="'column-' + index"
        :sortable="column.sort"
        :field="column.value"
        :header="$t(column.ts)"
        :style="'color:#000000!important;text-align:center;'"
      >
        <template v-if="column.childrem && column.name === 'Action'" #body="{ data }">
          <button
            v-if="data.terminals_statusCommercial === 1"
            class="menu-button"
            @click.prevent="data.show === true ? (data.show = false) : (data.show = true)"
          >
            <img src="@/assets/menu.png" alt="burguer-menu" />
          </button>
          <form
            @mouseleave="data.show = false"
            v-if="data.terminals_statusCommercial === 1"
          >
            <Menu
              id="overlay_menu"
              ref="menu"
              style="position: absolute"
              :popup="!data.show"
            >
              <template #start>
                <button
                  v-if="permissions.some((evt: any) => evt.id === 12)"
                  @click="
                    showPopupDrowComisionamiento = true;
                    terminalSelect(data);
                    data.show = false;
                  "
                  class="w-full p-link flex align-items-center p-2 pl-3 text-color hover:surface-200 border-noround"
                >
                  <Avatar
                    image="https://primefaces.org/cdn/primevue/images/avatar/amyelsner.png"
                    class="mr-2"
                    shape="circle"
                  />
                  <div class="flex flex-column align">
                    <div class="flexing-menu">
                      <img src="@/assets/checkbox-circle.svg" alt="" />
                      <span class="font-bold">{{ $t("message.planta.op1") }}</span>
                    </div>
                  </div>
                </button>

                <button
                  v-if="permissions.some((evt: any) => evt.id === 19)"
                  @click="
                    terminalSelect(data);
                    validUpdate(data);
                    data.show = false;
                  "
                  class="w-full p-link flex align-items-center p-2 pl-3 text-color hover:surface-200 border-noround"
                >
                  <Avatar
                    image="https://primefaces.org/cdn/primevue/images/avatar/amyelsner.png"
                    class="mr-2"
                    shape="circle"
                  />
                  <div class="flex flex-column align">
                    <div class="flexing-menu">
                      <img src="@/assets/checkbox-circle.svg" alt="" />
                      <span class="font-bold">{{ $t("message.planta.op2") }}</span>
                    </div>
                  </div>
                </button>

                <button
                  v-if="permissions.some((evt: any) => evt.id === 8)"
                  @click="
                    terminalSelect(data);
                    showPopupAddress = true;
                    data.show = false;
                  "
                  class="w-full p-link flex align-items-center p-2 pl-3 text-color hover:surface-200 border-noround"
                >
                  <Avatar
                    image="https://primefaces.org/cdn/primevue/images/avatar/amyelsner.png"
                    class="mr-2"
                    shape="circle"
                  />
                  <div class="flex flex-column align">
                    <div class="flexing-menu">
                      <img src="@/assets/checkbox-circle.svg" alt="" />
                      <span class="font-bold">{{ $t("message.planta.op3") }}</span>
                    </div>
                  </div>
                </button>

                <button
                  v-if="permissions.some((evt: any) => evt.id === 160)"
                  @click="
                    terminalSelect(data);
                    showPopupCambiarSiteid = true;
                    data.show = false;
                  "
                  class="w-full p-link flex align-items-center p-2 pl-3 text-color hover:surface-200 border-noround"
                >
                  <Avatar
                    image="https://primefaces.org/cdn/primevue/images/avatar/amyelsner.png"
                    class="mr-2"
                    shape="circle"
                  />
                  <div class="flex flex-column align">
                    <div class="flexing-menu">
                      <img src="@/assets/checkbox-circle.svg" alt="" />
                      <span class="font-bold">{{ $t("message.planta.op8") }}</span>
                    </div>
                  </div>
                </button>

                <!-- ESTOS BOTONES ESTAN ACTIVOS, PERO NO TIENE ENDPOINT, POR ESO LOS COMENTE -->

                <!-- <button  @click="
                  terminalSelect(data);
                  showPopupReactivar = true;
                data.show = false;
                " class="w-full p-link flex align-items-center p-2 pl-3 text-color hover:surface-200 border-noround">
                  <Avatar image="https://primefaces.org/cdn/primevue/images/avatar/amyelsner.png" class="mr-2"
                    shape="circle" />
                  <div class="flex flex-column align">
                    <div class="flexing-menu">
                      <img src="@/assets/checkbox-circle.svg" alt="" />
                      <span class="font-bold">{{
                        $t("message.planta.op9")
                      }}</span>
                    </div>
                  </div>
                </button> -->

                <button
                  v-if="permissions.some((evt: any) => evt.id === 169)"
                  @click="
                    terminalSelect(data);
                    showPopupCambiarBypass = true;
                    data.show = false;
                  "
                  class="w-full p-link flex align-items-center p-2 pl-3 text-color hover:surface-200 border-noround"
                >
                  <Avatar
                    image="https://primefaces.org/cdn/primevue/images/avatar/amyelsner.png"
                    class="mr-2"
                    shape="circle"
                  />
                  <div class="flex flex-column align">
                    <div class="flexing-menu">
                      <img src="@/assets/checkbox-circle.svg" alt="" />
                      <span class="font-bold">{{ $t("message.planta.op31") }}</span>
                    </div>
                  </div>
                </button>

                <!-- <button  @click="
                  terminalSelect(data);
                  showPopupReinicioRemoto = true;
                data.show = false;
                " class="w-full p-link flex align-items-center p-2 pl-3 text-color hover:surface-200 border-noround">
                  <Avatar image="https://primefaces.org/cdn/primevue/images/avatar/amyelsner.png" class="mr-2"
                    shape="circle" />
                  <div class="flex flex-column align">
                    <div class="flexing-menu">
                      <img src="@/assets/checkbox-circle.svg" alt="" />
                      <span class="font-bold">{{
                        $t("message.planta.op11")                  
                      }}</span>
                    </div>
                  </div>
                </button> -->

                <button
                  v-if="permissions.some((evt: any) => evt.id === 161)"
                  @click="
                    terminalSelect(data);
                    showPopupAgregarKit = true;
                    data.show = false;
                  "
                  class="w-full p-link flex align-items-center p-2 pl-3 text-color hover:surface-200 border-noround"
                >
                  <Avatar
                    image="https://primefaces.org/cdn/primevue/images/avatar/amyelsner.png"
                    class="mr-2"
                    shape="circle"
                  />
                  <div class="flex flex-column align">
                    <div class="flexing-menu">
                      <img src="@/assets/checkbox-circle.svg" alt="" />
                      <span class="font-bold">{{ $t("message.planta.op12") }}</span>
                    </div>
                  </div>
                </button>

                <button
                  v-if="permissions.some((evt: any) => evt.id === 162)"
                  @click="
                    terminalSelect(data);
                    showPopupCambiarKit = true;
                    data.show = false;
                  "
                  class="w-full p-link flex align-items-center p-2 pl-3 text-color hover:surface-200 border-noround"
                >
                  <Avatar
                    image="https://primefaces.org/cdn/primevue/images/avatar/amyelsner.png"
                    class="mr-2"
                    shape="circle"
                  />
                  <div class="flex flex-column align">
                    <div class="flexing-menu">
                      <img src="@/assets/checkbox-circle.svg" alt="" />
                      <span class="font-bold">{{ $t("message.planta.op13") }}</span>
                    </div>
                  </div>
                </button>

                <button
                  v-if="permissions.some((evt: any) => evt.id === 165)"
                  @click="
                    terminalSelect(data);
                    showPopupReasignacionPrincipal = true;
                    data.show = false;
                  "
                  class="w-full p-link flex align-items-center p-2 pl-3 text-color hover:surface-200 border-noround"
                >
                  <Avatar
                    image="https://primefaces.org/cdn/primevue/images/avatar/amyelsner.png"
                    class="mr-2"
                    shape="circle"
                  />
                  <div class="flex flex-column align">
                    <div class="flexing-menu">
                      <img src="@/assets/checkbox-circle.svg" alt="" />
                      <span class="font-bold">{{ $t("message.planta.op14") }}</span>
                    </div>
                  </div>
                </button>

                <!-- <button  @click="
                  terminalSelect(data);
                  showPopupServicioHorario = true;
                data.show = false;
                " class="w-full p-link flex align-items-center p-2 pl-3 text-color hover:surface-200 border-noround">
                  <Avatar image="https://primefaces.org/cdn/primevue/images/avatar/amyelsner.png" class="mr-2"
                    shape="circle" />
                  <div class="flex flex-column align">
                    <div class="flexing-menu">
                      <img src="@/assets/checkbox-circle.svg" alt="" />
                      <span class="font-bold">{{
                        $t("message.planta.op15")                  
                      }}</span>
                    </div>
                  </div>
                </button> -->

                <!-- <button  @click="
                  terminalSelect(data);
                  showPopupDNS = true;
                data.show = false;
                " class="w-full p-link flex align-items-center p-2 pl-3 text-color hover:surface-200 border-noround">
                  <Avatar image="https://primefaces.org/cdn/primevue/images/avatar/amyelsner.png" class="mr-2"
                    shape="circle" />
                  <div class="flex flex-column align">
                    <div class="flexing-menu">
                      <img src="@/assets/checkbox-circle.svg" alt="" />
                      <span class="font-bold">{{
                        $t("message.planta.op16")                  
                      }}</span>
                    </div>
                  </div>
                </button> -->

                <!-- <button  @click="
                  terminalSelect(data);
                  showPopupRenombrarWifi = true;
                data.show = false;
                " class="w-full p-link flex align-items-center p-2 pl-3 text-color hover:surface-200 border-noround">
                  <Avatar image="https://primefaces.org/cdn/primevue/images/avatar/amyelsner.png" class="mr-2"
                    shape="circle" />
                  <div class="flex flex-column align">
                    <div class="flexing-menu">
                      <img src="@/assets/checkbox-circle.svg" alt="" />
                      <span class="font-bold">{{
                        $t("message.planta.op17")                  
                      }}</span>
                    </div>
                  </div>
                </button> -->

                <!-- <button  @click="
                  terminalSelect(data);
                  showPopupCambiarPasswordWifi = true;
                data.show = false;
                " class="w-full p-link flex align-items-center p-2 pl-3 text-color hover:surface-200 border-noround">
                  <Avatar image="https://primefaces.org/cdn/primevue/images/avatar/amyelsner.png" class="mr-2"
                    shape="circle" />
                  <div class="flex flex-column align">
                    <div class="flexing-menu">
                      <img src="@/assets/checkbox-circle.svg" alt="" />
                      <span class="font-bold">{{
                        $t("message.planta.op18")                  
                      }}</span>
                    </div>
                  </div>
                </button> -->

                <button
                  v-if="permissions.some((evt: any) => evt.id === 166)"
                  @click="
                    terminalSelect(data);
                    showPopupActivarDatosPriory = true;
                    data.show = false;
                  "
                  class="w-full p-link flex align-items-center p-2 pl-3 text-color hover:surface-200 border-noround"
                >
                  <Avatar
                    image="https://primefaces.org/cdn/primevue/images/avatar/amyelsner.png"
                    class="mr-2"
                    shape="circle"
                  />
                  <div class="flex flex-column align">
                    <div class="flexing-menu">
                      <img src="@/assets/checkbox-circle.svg" alt="" />
                      <span class="font-bold">{{ $t("message.planta.op28") }}</span>
                    </div>
                  </div>
                </button>

                <button
                  v-if="permissions.some((evt: any) => evt.id === 167)"
                  @click="
                    terminalSelect(data);
                    showPopupDesactivarDatosPriory = true;
                    data.show = false;
                  "
                  class="w-full p-link flex align-items-center p-2 pl-3 text-color hover:surface-200 border-noround"
                >
                  <Avatar
                    image="https://primefaces.org/cdn/primevue/images/avatar/amyelsner.png"
                    class="mr-2"
                    shape="circle"
                  />
                  <div class="flex flex-column align">
                    <div class="flexing-menu">
                      <img src="@/assets/checkbox-circle.svg" alt="" />
                      <span class="font-bold">{{ $t("message.planta.op29") }}</span>
                    </div>
                  </div>
                </button>

                <button
                  v-if="permissions.some((evt: any) => evt.id === 172)"
                  @click="
                    terminalSelect(data);
                    showPopupReiniciarTerminal = true;
                    data.show = false;
                  "
                  class="w-full p-link flex align-items-center p-2 pl-3 text-color hover:surface-200 border-noround"
                >
                  <Avatar
                    image="https://primefaces.org/cdn/primevue/images/avatar/amyelsner.png"
                    class="mr-2"
                    shape="circle"
                  />
                  <div class="flex flex-column align">
                    <div class="flexing-menu">
                      <img src="@/assets/checkbox-circle.svg" alt="" />
                      <span class="font-bold">{{ $t("message.planta.f45") }}</span>
                    </div>
                  </div>
                </button>

                <button
                  v-if="permissions.some((evt: any) => evt.id === 20)"
                  @click="
                    terminalSelect(data);
                    showPopupDrowSus = true;
                    data.show = false;
                  "
                  class="w-full p-link flex align-items-center p-2 pl-3 text-color hover:surface-200 border-noround"
                >
                  <Avatar
                    image="https://primefaces.org/cdn/primevue/images/avatar/amyelsner.png"
                    class="mr-2"
                    shape="circle"
                  />
                  <div class="flex flex-column align">
                    <div class="flexing-menu">
                      <img src="@/assets/note-edit.svg" alt="" />
                      <span class="font-bold">{{ $t("message.planta.op4") }}</span>
                    </div>
                  </div>
                </button>

                <button
                  v-if="permissions.some((evt: any) => evt.id === 15)"
                  @click="
                    terminalSelect(data);
                    showPopupDrowBaja = true;
                    data.show = false;
                  "
                  class="w-full p-link flex align-items-center p-2 pl-3 text-color hover:surface-200 border-noround"
                >
                  <Avatar
                    image="https://primefaces.org/cdn/primevue/images/avatar/amyelsner.png"
                    class="mr-2"
                    shape="circle"
                  />
                  <div class="flex flex-column align">
                    <div class="flexing-menu">
                      <img src="@/assets/trash.svg" alt="" />
                      <span class="font-bold">{{ $t("message.planta.op5") }}</span>
                    </div>
                  </div>
                </button>

                <button
                  v-if="permissions.some((evt: any) => evt.id === 16)"
                  @click="
                    showPopupDrowSoporte = true;
                    terminalSelect(data);
                    data.show = false;
                  "
                  class="w-full p-link flex align-items-center p-2 pl-3 text-color hover:surface-200 border-noround"
                >
                  <Avatar
                    image="https://primefaces.org/cdn/primevue/images/avatar/amyelsner.png"
                    class="mr-2"
                    shape="circle"
                  />
                  <div class="flex flex-column align">
                    <div class="flexing-menu">
                      <img src="@/assets/handshake.svg" alt="" />
                      <span class="font-bold">{{ $t("message.planta.op6") }}</span>
                    </div>
                  </div>
                </button>

                <button
                  v-if="permissions.some((evt: any) => evt.id === 17)"
                  @click.prevent="gotografana(data)"
                  class="w-full p-link flex align-items-center p-2 pl-3 text-color hover:surface-200 border-noround"
                >
                  <Avatar
                    image="https://primefaces.org/cdn/primevue/images/avatar/amyelsner.png"
                    class="mr-2"
                    shape="circle"
                  />
                  <div class="flex flex-column align">
                    <div class="flexing-menu">
                      <img src="@/assets/signal.svg" alt="" />
                      <span class="font-bold">{{ $t("message.planta.op7") }}</span>
                    </div>
                  </div>
                </button>
              </template>
            </Menu>
          </form>
        </template>
        <template
          v-else-if="column.childrem && column.name === 'Estado Comercial'"
          #body="{ data }"
        >
          <button
            :class="data.terminals_statusCommercial === 1 ? 'btn-active' : 'btn-inactive'"
          >
            {{
              data.terminals_statusCommercial === 1
                ? $t("message.planta.m02")
                : $t("message.planta.m03")
            }}
          </button>
        </template>
        <template
          v-else-if="column.name === 'Plan de Servicio'"
          #body="{ data }"
        >
          {{ data.options_nickname ? data.options_nickname : data.serviceplans_name }}
        </template>
        <template
          v-else-if="column.childrem && column.name === 'Estado Tecnico'"
          #body="{ data }"
        >
          <button
            :class="data.terminals_statusTechnic === 1 ? 'btn-active' : 'btn-inactive'"
          >
            {{
              data.terminals_statusTechnic === 1
                ? $t("message.planta.m02")
                : $t("message.planta.m03")
            }}
          </button>
        </template>
        <template
          v-else-if="column.childrem && column.name === 'Ultima Conexión'"
          #body="{ data }"
        >
          {{ String(data.billingConsumption_lastDailyDate).substring(0, 10) }}
        </template>

        <template
          v-else-if="column.childrem && column.name === 'Site ID'"
          #body="{ data }"
        >
          {{ String(data.terminals_nickname).substring(0, 20) }}
        </template>

        <template
          v-else-if="column.childrem && column.name === 'Dirección'"
          #body="{ data }"
        >
          {{ String(data.directions_formattedAddress).substring(0, 40) }}
        </template>
      </Column>
    </DataTable>

    <div v-else class="spinner-content">
      <ProgressSpinner
        style="width: 50px; height: 50px"
        strokeWidth="8"
        fill="transparent"
        animationDuration=".5s"
        aria-label="Custom ProgressSpinner"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Popup from "../../components/planta-conte/Popup.vue";
import PopupDrop from "../../components/planta-conte/PopupDropDown.vue";
import PopupComi from "../../components/planta-conte/PopupComisionamiento.vue";

import ChangeAdrees from "./poppup/updateAddress.vue";
import BajaValidation from "./poppup/bajaValidation.vue";
import store from "../../store/index";
import { ApiResponsePlantaArray } from "../../interface/planta";
import { mapGetters, mapState, mapActions } from "vuex";
import newPlant from "./newService.vue";
import selectedPrincipal from "./poppup/selectedPrincipal.vue";
import vSelect from "vue-select";
import DataTable from "primevue/datatable";
import Button from "primevue/button";
import Column from "primevue/column";
import "primeflex/primeflex.css";
import "primevue/resources/themes/lara-light-blue/theme.css";
import "primevue/resources/primevue.min.css"; /* Deprecated */
import Menu from "primevue/menu";
import Message from "primevue/message";
import ProgressSpinner from "primevue/progressspinner";
import { getLocale } from "@/utils/auth";
import { saveAs } from "file-saver";
import CambiarSiteID from "./poppup/cambiarSiteID.vue";
import ReactivarServicio from "./poppup/reactivarServicio.vue";
import ActivacionBypass from "./poppup/activacionBypass.vue";
import ReinicioRemoto from "./poppup/reinicioRemoto.vue";
import AgregarKit from "./poppup/agregarKit.vue";
import CambiarKit from "./poppup/cambiarKit.vue";
import ReasignacionPrincipal from "./poppup/reasignacionPrincipal.vue";
import SuspenderServicioHorario from "./poppup/suspenderServicioHorario.vue";
import DnsPersonalizado from "./poppup/dnsPersonalizado.vue";
import RenombrarRedWifi from "./poppup/renombrarRedWifi.vue";
import CambiarPasswordWifi from "./poppup/cambiarPasswordWifi.vue";
import DesactivarDatosPrioritarios from "./poppup/desactivarDatosPrioritarios.vue";
import ActivarDatosPrioritarios from "./poppup/activarDatosPrioritarios.vue";
import ReiniciarTerminal from "./poppup/reiniciarTerminal.vue";

import PerfilTabla from "./poppup/perfilTabla.vue";
import { options } from "toastr";

interface CommisioningItem {
  id: number;
  terminalId: number;
  name: string;
  url: string;
  bucket: string;
  folder: string;
}

export default defineComponent({
  name: "planta",
  components: {
    Popup,
    PopupDrop,
    newPlant,
    selectedPrincipal,
    vSelect,
    DataTable,
    Column,
    Menu,
    ChangeAdrees,
    PopupComi,
    Button,
    BajaValidation,
    Message,
    ProgressSpinner,
    CambiarSiteID,
    ReactivarServicio,
    ActivacionBypass,
    ReinicioRemoto,
    AgregarKit,
    CambiarKit,
    ReasignacionPrincipal,
    SuspenderServicioHorario,
    DnsPersonalizado,
    RenombrarRedWifi,
    CambiarPasswordWifi,
    ActivarDatosPrioritarios,
    DesactivarDatosPrioritarios,
    ReiniciarTerminal,
    PerfilTabla,
  },

  computed: {
    ...mapState({
      coordenate: (state: any) => state.AUTH.coordenateData,
      user: (state: any) => state.AUTH.user,
      token: (state: any) => state.AUTH.token,
      isLoading: (state: any) => state.AUTH.isLoadingInter,
    }),
    ...mapGetters(["filteredTerminals", "permissions", "mycolumns"]),
    query: {
      get() {
        return store.state.dataFilter.text;
      },
      set(value: any) {
        store.commit("SET_FILTER_TERMINALS", {
          filter: "text",
          value,
        });
      },
    },
  },
  data() {
    return {
      slider: [] as any[],
      showPopup: false,
      showPopupPrincipal: false,
      showPopupAddress: false,
      showPopupDrow: false,
      showPopupDrowSus: false,
      showPopupDrowBaja: false,
      showPopupDrowSoporte: false,
      showPopupBajaValidation: false,
      showPopupDrowComisionamiento: false,
      showPopupCambiarSiteid: false,
      showPopupReactivar: false,
      showPopupCambiarBypass: false,
      showPopupReinicioRemoto: false,
      showPopupAgregarKit: false,
      showPopupCambiarKit: false,
      showPopupReasignacionPrincipal: false,
      showPopupServicioHorario: false,
      showPopupDNS: false,
      showPopupRenombrarWifi: false,
      showPopupCambiarPasswordWifi: false,
      showPopupDesactivarDatosPriory: false,
      showPopupActivarDatosPriory: false,
      showPopupReiniciarTerminal: false,
      showPopupPerfilTabla: false,
      myPlansSelected: [],
      newService: {
        siteid: "",
        planservice: "",
        direction: "",
        serialnumber: "",
        suscriptor: "",
        optionsId:"",
      },
      columnTablet: [
        {
          name: "Cliente",
          ts: "message.planta.c1",
          value: "principalName",
          mode: "ASC",
          sort: true,
          childrem: false,
          width: "5",
          rule: 24,
        },
        {
          name: "Site ID",
          ts: "message.planta.c2",
          value: "terminals_nickname",
          mode: "ASC",
          sort: true,
          childrem: true,
          width: "8",
          rule: 25,
        },
        {
          name: "Nombre Suscriptor",
          value: "subscribers_name",
          ts: "message.planta.c3",
          mode: "ASC",
          sort: true,
          childrem: false,
          width: "5",
          rule: 45,
        },
        {
          name: "Estado Comercial",
          ts: "message.planta.c4",
          value: "terminals_statusCommercial",
          mode: "ASC",
          sort: true,
          childrem: true,
          width: "6",
          rule: 27,
        },
        {
          name: "Estado Tecnico",
          ts: "message.planta.c5",
          value: "terminals_statusTechnic",
          mode: "ASC",
          sort: true,
          childrem: true,
          width: "6",
          rule: 28,
        },
        {
          name: "Numero de Serie",
          ts: "message.planta.c6",
          value: "kitSerialNumber",
          mode: "ASC",
          sort: true,
          childrem: false,
          width: "8",
          rule: 29,
        },
        {
          name: "Ultima Conexión",
          ts: "message.planta.c7",
          value: "billingConsumption_lastDailyDate",
          mode: "ASC",
          sort: true,
          childrem: true,
          width: "16",
          rule: 30,
        },
        {
          name: "Consumo Diario (U.C)",
          ts: "message.planta.c8",
          value: "billingConsumption_lastDailyTotalGB",
          mode: "ASC",
          sort: true,
          childrem: false,
          width: "5",
          rule: 31,
        },
        {
          name: "Consumo de Ciclo GB",
          ts: "message.planta.c9",
          value: "billingConsumption_lastMonthTotalGB_0",
          mode: "ASC",
          sort: true,
          childrem: false,
          width: "5",
          rule: 32,
        },
        {
          name: "Plan de Servicio",
          ts: "message.planta.c10",
          value: "options_nickname",
          value2: "serviceplans_name",
          mode: "ASC",
          sort: true,
          childrem: false,
          width: "7.5",
          rule: 33,
        },
        {
          name: "Dirección",
          ts: "message.planta.c11",
          value: "directions_formattedAddress",
          mode: "ASC",
          sort: true,
          childrem: true,
          width: "15",
          rule: 34,
        },
        {
          name: "Long",
          ts: "message.planta.c12",
          value: "directions_longitude",
          mode: "ASC",
          sort: true,
          childrem: true,
          width: "5",
          rule: 35,
        },
        {
          name: "lat",
          ts: "message.planta.c13",
          value: "directions_latitude",
          mode: "ASC",
          sort: true,
          childrem: false,
          width: "5",
          rule: 36,
        },
        {
          name: "Action",
          ts: "message.planta.c14",
          value: null,
          mode: "ASC",
          sort: false,
          childrem: true,
          width: "5",
          rule: 37,
        },
      ],
      myColumnTablet: [],
      changeplan: "",
      changeserviceplan: [],
      busqueda: null,
      dataSource: [] as ApiResponsePlantaArray,
      originalDataSource: [] as ApiResponsePlantaArray,
      resultadosBusqueda: [] as ApiResponsePlantaArray,
      terminalSelected: null,
      ticketSoporte: {
        request: "",
        descripcion: "",
        mediaComunication: "",
        dataComunication: "",
        typeContact: "",
        dataTypeContact: "",
      },
      problemtypes: {
        dataMediaComunicationId: [],
        dataPinPriorityId: [],
        dataPinProblem: [],
        dataTypeContactId: [],
      },
      // problemtypes1: [],
      show: true,
      uploadfile: "",
      commisioning: [] as CommisioningItem[],
      // fileToUpload: null as File | null,
      selectedFile: null as File | null,
      isLoadingUpdateServicePlan: false,
    };
  },
  watch: {
    filteredTerminals(newFilteredTerminals) {
      // Verificar si hay datos y llamar a reelAlert
      if (newFilteredTerminals.length > 0) {
        this.reelAlert();
      }
    },
  },
  mounted() {
    this.problemtype();
    // this.selectComisionamiento();
    console.log("PERMISOS", this.permissions);
    setTimeout(() => {
      var self = this;
      var mycolumns: (
        | {
            name: string;
            value: string;
            mode: string;
            sort: boolean;
            childrem: boolean;
            width: string;
            rule: number;
          }
        | {
            name: string;
            value: null;
            mode: string;
            sort: boolean;
            childrem: boolean;
            width: string;
            rule: number;
          }
      )[] = [];
      this.columnTablet.forEach((columnT) => {
        if (this.permissions.some((evt: any) => evt.id === columnT.rule) === true) {
          mycolumns.push(columnT);
        }
      });
      this.mycolu(mycolumns);

      if (this.permissions.some((evt: any) => evt.id === 23)) {
        var data = {
          endpoint: 1,
        };
        this.serviceline(data);
      }
    }, 2000);
  },

  methods: {
    // ...mapActions(['SERVICELINEUSERCONFIG']),
    // ...mapActions(['DOWNLOADFILE']),
    ...mapActions(["DOWNLOADFILE", "UPLOADFILE", "showToast"]),
    reelAlert() {
      // Tu array de objetos
      const filteredTerminals = this.filteredTerminals;
      // const alert: any = []

      // Función para obtener el número de serviceplans_name
      function obtenerNumeroDeServicePlan(objeto: any) {
        if (objeto.serviceplans_name) {
          const regex = /(\d+)/; // Expresión regular para extraer números
          const match = objeto.serviceplans_name.match(regex);

          // Verificar si se encontró un número y devolverlo
          return match ? parseInt(match[0]) : null;
        } else {
          return null;
        }
      }

      // Ejemplo de uso
      filteredTerminals.forEach((objeto: any) => {
        const numeroDeServicePlan = obtenerNumeroDeServicePlan(objeto);

        // console.log("numeroDeServicePlan", objeto.serviceplans_name, objeto.serviceplans_productReferenceId, numeroDeServicePlan)

        if (numeroDeServicePlan) {
          let base = 0;
          if (numeroDeServicePlan >= 1000) {
            base = (numeroDeServicePlan / 1000) * 1024;
          } else if (numeroDeServicePlan < 1000 && numeroDeServicePlan >= 40) {
            base =
              numeroDeServicePlan < 100
                ? (numeroDeServicePlan / 1000) * 1024
                : (numeroDeServicePlan / 1000) * 1024;
          } else {
            base = numeroDeServicePlan * 1024;
          }
          // console.log(base)
          const porcentaje = parseFloat(
            ((objeto.billingConsumption_lastMonthTotalGB_0 / base) * 100).toFixed(2)
          );
          if (porcentaje > 90) {
            objeto.porcentaje = porcentaje;
            this.slider.push(objeto);
            // console.log(`El número de serviceplans_name es: ${numeroDeServicePlan} ------ ${objeto.terminals_nickname} ----- ${objeto.billingConsumption_lastMonthTotalGB_0} ... ${porcentaje}`);
            // console.log(objeto)
          }
        }
      });

      // console.log(this.slider)
    },
    getColor(value: any) {
      return value > 90 ? "green" : "red";
    },
    handleAnimationIteration() {
      const slider = this.$refs.slider as HTMLDivElement;
      const messages = slider.querySelectorAll(".message");

      // Mueve el primer mensaje al final del slider
      // slider.appendChild(messages[0].cloneNode(true));
      // messages[0].remove();

      // Reinicia la animación
      slider.style.animation = "none";
      void slider.offsetWidth;
      slider.style.animation = "slide 10s linear infinite";
    },
    // Método para manejar el cambio de archivo
    handleFileChange(event: any) {
      const file = event.target.files[0];
      if (file) {
        this.selectedFile = file;
      }
      // console.log("Archivo seleccionado:", this.selectedFile);
    },

    cargarArchivo() {
      if (!this.selectedFile) {
        store.dispatch("showToast", {
          message: "Seleccione Archivo",
          type: "warning",
        });
        return;
      }
      // Crear un objeto FormData
      let formData = new FormData();

      // Agregar el archivo al FormData
      formData.append("file", this.selectedFile);

      // Construir la URL completa del archivo
      const fileName = this.selectedFile.name;
      const bucketName = "bifrost-app-storage";
      const folderName = "test2";
      const fileUrl = `https://${bucketName}.s3.amazonaws.com/${folderName}/${fileName}`;

      // Agregar los parámetros al FormData
      formData.append("folderName", folderName);
      formData.append("bucketName", bucketName);

      store
        .dispatch("UPLOADFILE", formData)
        .then((response) => {
          // console.log(this.newService);
          store.dispatch("showToast", {
            message: "SE SUBIÓ CORRECTAMENTE",
            type: "success",
          });

          const awsUrl = {
            name: fileName,
            folder: folderName,
            bucket: bucketName,
            url: fileUrl,
          };
          var data = {
            endpoint: 1,
          };
          this.serviceline(data);
          const terminal = this.terminalSelected ? this.terminalSelected["id"] : null;
          this.insertarArchivoEnBD(awsUrl, terminal);
        })
        .catch((error) => {
          console.log("error", error);
        });
    },

    insertarArchivoEnBD(awsUrl: any, terminal: any) {
      // Crear el payload con los detalles del archivo
      const payload = {
        name: awsUrl.name,
        folder: awsUrl.folder,
        bucket: awsUrl.bucket,
        url: awsUrl.url,
        terminalId: terminal,
      };
      console.log("payload", payload);
      store
        .dispatch("INSERTFILE", payload)
        .then((response) => {
          store.dispatch("showToast", {
            message: "SE INSERTO CORRECTAMENTE",
            type: "success",
          });
        })
        .catch((error) => {
          console.log("error", error);
        });
    },

    sendTicket() {
      console.log("ticketSoporte", this.ticketSoporte);
    },
    exportCSV() {
      console.log("USER EXCEL");
      const uniquePrincipalIds = Array.from(
        new Set(this.filteredTerminals.map((item: any) => item.principalId))
      );
      console.log("USER EXCEL", uniquePrincipalIds);

      const payload = {
        principalIds: uniquePrincipalIds,
      };

      console.log("payload", payload);
      store
        .dispatch("EXCELTERMINAL", payload)
        .then((response) => {
          console.log("excel data", response.data.data);
          const data = response.data.data;

          if (data) {
            // Convert the data to CSV
            const csvContent = this.convertToCSV(data);
            const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
            saveAs(blob, "export.csv");
          }

          store.dispatch("showToast", {
            message: "SE DESCARGO LA INFORMACION EN EXCEL EXITOSAMENTE",
            type: "success",
          });
        })
        .catch((error) => {
          console.log("error", error);
        });
    },

    convertToCSV(data: any[]): string {
      // Define the headers in the desired order
      const headers = [
        "Site",
        "Id",
        "detail",
        "kit",
        "Activation Date",
        "Commercial Status",
        "Technical Status",
        "Service plan",
        "Total Priority data",
        "Total Best Effort data",
        "Principal",
        "Owner",
        "Country",
      ];

      // Create the header row
      const headerRow = headers.join(",");

      // Map each row to match the header order
      const rows = data.map((item) => [
        item.Site || "",
        item.Id || "",
        item.detail || "",
        item.kit || "",
        item["Activation Date"] || "",
        item["commercial status"] || "",
        item["technical status"] || "",
        item["Service plan"] || "",
        item["priority total data"] || "",
        item["standar total data"] || "",
        item.principal || "",
        item.owner || "",
        item.Country || "",
      ]);

      // Combine headers and rows into a single CSV string
      const csvContent = [headerRow, ...rows.map((row) => row.join(","))].join("\n");
      return csvContent;
    },
    gotografana(terminal: any) {
      // console.log("ENTRO")
      // console.log(terminal)
      // console.log(getLocale())
      // console.log(terminal.userTerminalId)
      // console.log("ENTRO")

      if (getLocale() == "es") {
        var data: { [key: string]: any } = {
          // siteId: terminal.terminals_serviceLineNumber,
          userTerminalId: terminal.userTerminalId,
          serviceLineNumber: terminal.serviceLineNumber,
        };
        data["var-principal"] = terminal.principal_id;
        store
          .dispatch("GETMONITORINGURL", data)
          .then((response) => {
            window.open(response.data.snapshotUrl, "_blank");
          })
          .catch((error) => {
            console.log("error", error);
          });
      } else if (getLocale() == "pt") {
        var data: { [key: string]: any } = {
          // siteId: terminal.terminals_serviceLineNumber,
          userTerminalId: terminal.userTerminalId,
          serviceLineNumber: terminal.serviceLineNumber,
        };
        data["var-principal"] = terminal.principal_id;
        store
          .dispatch("GETMONITORINGURLPT", data)
          .then((response) => {
            window.open(response.data.snapshotUrl, "_blank");
          })
          .catch((error) => {
            console.log("error", error);
          });
      } else {
        var data: { [key: string]: any } = {
          // siteId: terminal.terminals_serviceLineNumber,
          userTerminalId: terminal.userTerminalId,
          serviceLineNumber: terminal.serviceLineNumber,
        };
        console.log("datauser", data);
        data["var-principal"] = terminal.principal_id;
        store
          .dispatch("GETMONITORINGURLEN", data)
          .then((response) => {
            window.open(response.data.snapshotUrl, "_blank");
          })
          .catch((error) => {
            console.log("error", error);
          });
      }
    },

    // gotografana(terminal: any) {
    //   // Determina el idioma actual
    //   const locale = getLocale();
    //   var data: { [key: string]: any } = {
    //     userTerminalId: terminal.userTerminalId,
    //     "var-principal": terminal.principal_id,
    //   };

    //   // Selecciona la acción (endpoint) basada en el idioma
    //   let action: string;

    //   if (locale === "es") {
    //     action = "GETMONITORINGURL"; // Acción para español
    //   } else if (locale === "pt") {
    //     action = "GETMONITORINGURLPT"; // Acción para portugués
    //   } else {
    //     action = "GETMONITORINGURLEN"; // Acción para inglés u otros
    //   }

    //   // Ejecuta la acción correspondiente
    //   store
    //     .dispatch(action, data)
    //     .then((response) => {
    //       let url = response.data.snapshotUrl;
    //       const hasQueryParams = url.includes("?");
    //       const separator = hasQueryParams ? "&" : "?";

    //       // Construye la URL con parámetros adicionales
    //       const urlWithParams = `${url}${separator}kiosk&terminalId=${terminal.terminal_id}&subscriptionId=${terminal.subscription_id}`;

    //       window.open(urlWithParams, "_blank");
    //     })
    //     .catch((error) => {
    //       console.log("error", error);
    //     });
    // },

    mycolu(data: any) {
      if (data.length > 0) {
        this.myColumnTablet = data;
      } else {
        return [];
      }
    },
    serviceline(data: any) {
      store
        // .dispatch("SERVICELINE", data)
        .dispatch("SERVICELINEUSERCONFIG", data)
        .then((response) => {
          // console.log(this.newService);
          store.dispatch("showToast", {
            message: "LISTADO DE PLANTA",
            type: "success",
          });
          this.originalDataSource = response.data;
          this.showPopup = false;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    soporte() {
      var data = {
        username: "develop@bifrost.pe",
        password: "developbifrost",
        search: "TEST2112",
        problem: "1",
        description: "Descripción Ticket Prueba Starlink",
        fullname: "Usuario Prueba",
        email: "leonardo25457@gmail.com",
      };
      store
        .dispatch("SOPORTE", data)
        .then((response) => {
          console.log(this.newService);
          store.dispatch("showToast", {
            message: "SE ENVIO EL TICKET DE SOPORTE",
            type: "success",
          });
          this.showPopupDrowSoporte = false;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    serviceplanByTerminal(terminal: any) {
      var data = {
        endpoint: 14,
        type: "data",
        variables: [
          {
            name: "$principalId",
            value: terminal.principalId,
          },
          {
            name: "$onlyserviceplans",
            value: "serviceplans",
          },
        ],
      };
      store
        .dispatch("SERVICELINEUSERCONFIG", data)
        .then((response) => {
          this.myPlansSelected = response.data.data;
          console.log("services", this.myPlansSelected);
          this.myPlansSelected = response.data.data.map(function (cadena: any) {
            return {
              id: cadena.optionsId,
              name: cadena.name,
              nickname: cadena.nickname,
              productReferenceId: cadena.productReferenceId,
              serviceplanId: cadena.serviceplanId,
              optionsId: cadena.optionsId,
            };
          });
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    problemtype() {
      store
        .dispatch("PROBLEMTYPE")
        .then((response) => {
          this.problemtypes = response.data.data;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    // chagePlanService(terminal: any, planNew: any) {
    //   this.isLoadingUpdateServicePlan = true
    //   let data1 = this.getPlanGB((this.terminalSelected as any).serviceplans_name)
    //   let data2 = this.getPlanGB((this.newService.planservice as any).name)
    //   var data = {
    //     endpoint: 37,
    //     type: "api",
    //     requestBody: {
    //       serviceplansIdOld: terminal.serviceplans_id,
    //       serviceplansIdNew: planNew.planservice.serviceplanId,
    //       deviceId: terminal.id,
    //       accountNumber: terminal.accountNumber,
    //       languaje: getLocale(),
    //       changeVersion: data2 > data1 ? 'Upgrade' : 'Downgrade'
    //     }
    //   }
    //   // console.log(data)
    //   // console.log(data2 > data1 ? 'Upgrade' : 'Downgrade')
    //   let languajeMsjUpgrade = data.requestBody.languaje === 'es' ? `Ahora usted está haciendo uso del nuevo plan: ${(this.newService.planservice as any).name}. El costo será prorrateado en la próxima factura.` :
    //     data.requestBody.languaje === 'en' ? `Now you are using the new plan: ${(this.newService.planservice as any).name}. The cost will be prorated on the next invoice.` :
    //       data.requestBody.languaje === 'pt' ? `Agora você está usando o novo plano: ${(this.newService.planservice as any).name}. O custo será rateado na próxima fatura.` : null

    //   let languajeMsjDowngrade = data.requestBody.languaje === 'es' ? `Usted continúa usando del plan ${(this.terminalSelected as any).serviceplans_name} hasta el fin del ciclo, Al inicio del siguiente ciclo (próximo día 6) empezará a usar el plan ${(this.newService.planservice as any).name}. El costo será prorrateado en la próxima factura.` :
    //     data.requestBody.languaje === 'en' ? `You continue using the plan ${(this.terminalSelected as any).serviceplans_name} until the end of the cycle, At the beginning of the next cycle (next day 6) you will start using the plan ${(this.newService.planservice as any).name}. The cost will be prorated on the next invoice.` :
    //       data.requestBody.languaje === 'pt' ? `Você continua usando o plano ${(this.terminalSelected as any).serviceplans_name} até o final do ciclo, No início do próximo ciclo (próximo dia 6) você começará a usar o plano ${(this.newService.planservice as any).name}. O custo será rateado na próxima fatura.` : null

    //   store
    //     .dispatch("VERIFYADDRESS", data)
    //     .then((response) => {
    //       console.log(response);
    //       store.dispatch("showToast", {
    //         message: data.requestBody.changeVersion === 'Upgrade' ? languajeMsjUpgrade : languajeMsjDowngrade,
    //         type: "success",
    //       });

    //       var data2 = {
    //         endpoint: 1,
    //       };
    //       this.serviceline(data2);
    //       this.showPopupDrow = false;
    //       this.isLoadingUpdateServicePlan = false
    //     })
    //     .catch((error) => {
    //       console.log("error", error);
    //       this.isLoadingUpdateServicePlan = false
    //     });
    // },

    // chagePlanService(terminal: any, planNew: any) {
    //   this.isLoadingUpdateServicePlan = true;

    //   let data1 = this.getPlanGB((this.terminalSelected as any).serviceplans_name);
    //   let data2 = this.getPlanGB((this.newService.planservice as any).name);

    //   var data = {
    //     serviceplansIdOld: terminal.serviceplans_id,
    //     serviceplansIdNew: planNew.planservice.serviceplanId,
    //     deviceId: terminal.id,
    //     accountNumber: terminal.accountNumber,
    //     languaje: getLocale(),
    //     changeVersion: data2 > data1 ? 'Upgrade' : 'Downgrade',
    //   };

    //   let languajeMsjUpgrade = data.languaje === 'es'
    //     ? `Ahora usted está haciendo uso del nuevo plan: ${(this.newService.planservice as any).name}. El costo será prorrateado en la próxima factura.`
    //     : data.languaje === 'en'
    //       ? `Now you are using the new plan: ${(this.newService.planservice as any).name}. The cost will be prorated on the next invoice.`
    //       : data.languaje === 'pt'
    //         ? `Agora você está usando o novo plano: ${(this.newService.planservice as any).name}. O custo será rateado na próxima fatura.`
    //         : null;

    //   let languajeMsjDowngrade = data.languaje === 'es'
    //     ? `Usted continúa usando del plan ${(this.terminalSelected as any).serviceplans_name} hasta el fin del ciclo. Al inicio del siguiente ciclo (próximo día 6), empezará a usar el plan ${(this.newService.planservice as any).name}. El costo será prorrateado en la próxima factura.`
    //     : data.languaje === 'en'
    //       ? `You continue using the plan ${(this.terminalSelected as any).serviceplans_name} until the end of the cycle. At the beginning of the next cycle (next day 6), you will start using the plan ${(this.newService.planservice as any).name}. The cost will be prorated on the next invoice.`
    //       : data.languaje === 'pt'
    //         ? `Você continua usando o plano ${(this.terminalSelected as any).serviceplans_name} até o final do ciclo. No início do próximo ciclo (próximo dia 6), você começará a usar o plano ${(this.newService.planservice as any).name}. O custo será rateado na próxima fatura.`
    //         : null;

    //   store
    //     .dispatch("UPDATESERVICEPLANDB", data)
    //     .then((response) => {
    //       console.log(response);

    //       store.dispatch("showToast", {
    //         message: data.changeVersion === 'Upgrade' ? languajeMsjUpgrade : languajeMsjDowngrade,
    //         type: "success",
    //       });

    //       var data2 = {
    //         endpoint: 1,
    //       };

    //       this.serviceline(data2);
    //       this.showPopupDrow = false;
    //       this.isLoadingUpdateServicePlan = false;
    //     })
    //     .catch((error) => {
    //       console.log("error", error);
    //       this.isLoadingUpdateServicePlan = false;
    //     });
    // },

    chagePlanService(terminal: any, planNew: any) {
      // Activar indicador de carga
      this.isLoadingUpdateServicePlan = true;

      // Obtener datos de los planes
      // let data1 = this.getPlanGB((this.terminalSelected as any).serviceplans_name);
      // let data2 = this.getPlanGB((this.newService.planservice as any).name);

      let data1 = Number(
        this.getPlanGB((this.terminalSelected as any).serviceplans_name)
      );
      let data2 = Number(this.getPlanGB((this.newService.planservice as any).name));

      // Logs de los planes para comparar tamaños
      console.log("Plan actual (GB):", data1);
      console.log("Plan nuevo (GB):", data2);

      // Validar y obtener el idioma
      // let languaje = getLocale() || "es"; // Usar 'es' como valor predeterminado si getLocale() retorna undefined
      // Se extrae el código de dos letras y se pasa a minúsculas
      let languaje = (getLocale() || "es").slice(0, 2).toLowerCase();
      const validLanguages = ["es", "en", "pt"] as const; // Marcar como constante para uso con tipos

      if (!validLanguages.includes(languaje as typeof validLanguages[number])) {
        console.warn(
          `Idioma no válido detectado: ${languaje}. Usando 'es' como valor por defecto.`
        );
        languaje = "es"; // Valor por defecto
      }
      console.log('nuevi',planNew)
      // Construcción del objeto de datos para la actualización
      var data = {
        serviceplansIdOld: terminal.serviceplans_id,
        serviceplansIdNew: planNew.planservice.serviceplanId,
        optionIdNew: planNew.planservice.optionsId,
        optionIdOld: terminal.options_id,
        deviceId: terminal.id,
        accountNumber: terminal.accountNumber,
        languaje: languaje as "es" | "en" | "pt", // Asegurar el tipo del idioma
        changeVersion: data2 > data1 ? "Upgrade" : "Downgrade",
      };

      // Log del objeto data que será enviado a UPDATESERVICEPLANDB
      console.log("Datos enviados a UPDATESERVICEPLANDB:", data);

      // Construcción de mensajes personalizados en función del idioma
      const messages = {
        Upgrade: {
          es: `Ahora usted está haciendo uso del nuevo plan: ${
            (this.newService.planservice as any).name
          }. El costo será prorrateado en la próxima factura.`,
          en: `Now you are using the new plan: ${
            (this.newService.planservice as any).name
          }. The cost will be prorated on the next invoice.`,
          pt: `Agora você está usando o novo plano: ${
            (this.newService.planservice as any).name
          }. O custo será rateado na próxima fatura.`,
        },
        Downgrade: {
          es: `Usted continúa usando del plan ${
            (this.terminalSelected as any).serviceplans_name
          } hasta el fin del ciclo. Al inicio del siguiente ciclo (próximo día 6), empezará a usar el plan ${
            (this.newService.planservice as any).name
          }. El costo será prorrateado en la próxima factura.`,
          en: `You continue using the plan ${
            (this.terminalSelected as any).serviceplans_name
          } until the end of the cycle. At the beginning of the next cycle (next day 6), you will start using the plan ${
            (this.newService.planservice as any).name
          }. The cost will be prorated on the next invoice.`,
          pt: `Você continua usando o plano ${
            (this.terminalSelected as any).serviceplans_name
          } até o final do ciclo. No início do próximo ciclo (próximo dia 6), você começará a usar o plano ${
            (this.newService.planservice as any).name
          }. O custo será rateado na próxima fatura.`,
        },
      };

      // Seleccionar el mensaje basado en el idioma y el tipo de cambio
      const message =
        data.changeVersion === "Upgrade"
          ? messages.Upgrade[languaje as "es" | "en" | "pt"] // Asegurar que languaje es uno de los valores válidos
          : messages.Downgrade[languaje as "es" | "en" | "pt"];

      // Log del mensaje seleccionado
      console.log("Mensaje seleccionado:", message);

      // Llamada a la acción Vuex
       store
         .dispatch("UPDATESERVICEPLANDB", data)
         .then((response) => {
           console.log("Respuesta del servidor:", response);

           // Mostrar notificación de éxito
           store.dispatch("showToast", {
             message: message, // Usar el mensaje dinámico basado en el idioma
             type: "success",
           });

         // Lógica posterior al éxito
           var data2 = {
             endpoint: 1,
           };

           console.log("Datos enviados a serviceline:", data2);

           this.serviceline(data2);
           this.showPopupDrow = false;
           this.isLoadingUpdateServicePlan = false;
         })
         .catch((error) => {
           console.log("Error al actualizar el plan:", error);
           this.isLoadingUpdateServicePlan = false;
         });
    },

    getPlanGB(servicePlanName: any) {
      // Expresión regular para detectar gigabytes (GB), terabytes (TB) o solo el número
      console.log("servicePlanName", servicePlanName);
      const match = servicePlanName.match(/(\d+)\s*(GB|TB)?\s*-\s*\w+/);
      console.log("MATCH", match);
      if (match) {
        let value = parseInt(match[1], 10);
        let unit = match[2];

        if (unit === "TB") {
          value *= 1024; // Convertir TB a GB
        }

        return value;
      }
      return "N/A"; // Retornar 'N/A' si la información no se encuentra
    },
    suspender(e: any) {
      store
        .dispatch("HELLOWORD")
        .then((response) => {
          store.dispatch("showToast", {
            message: "SE SUSPENDIO EXISTOSAMENTE UN SERVICIO",
            type: "success",
          });
          this.showPopupDrowSus = false;
          var data = {
            body: `prueba solicitud de suspencion ${e.terminals_nickname} / ${e.kitSerialNumber}`,
            asunto: "baja total",
            correo_destino: "juliocesar.herranlarosa@telefonica.com",
          };
          store
            .dispatch("SEND_MAIL", data)
            .then((response) => {})
            .catch((error) => {
              console.log("error", error);
            });
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    // bajaTotal(e: any, user: any) {
    //   console.log("Usuario recibido:", user);
    //   console.log("Terminal seleccionado:", e);
    //   console.log(user)
    //   var datavalid = {
    //     endpoint: 26,
    //     type: "api",
    //     requestBody: {
    //       accountNumber: e.accountNumber,
    //       deviceId: e.id,
    //       userId: user.userId
    //     },
    //   };

    //   console.log("Datos enviados al backend:", datavalid);

    //   console.log("Datos enviados al backend requestbody:", JSON.stringify(datavalid.requestBody));

    //   store.dispatch("DELETETERMINALDB", datavalid).then((response3) => {
    //     console.log("Respuesta del backend:", response3);
    //     if (response3.data.code === 200) {
    //       console.log("Baja exitosa.");
    //       store.dispatch("showToast", {
    //         message: "Baja Correcta",
    //         type: "success",
    //       });

    //       this.showPopupBajaValidation = true
    //     } else {
    //       console.log("Error en la baja:", response3.data);
    //       store.dispatch("showToast", {
    //         message: "Error al dar de baja",
    //         type: "success",
    //       });
    //     }

    //   }).catch((error) => {
    //     console.log("Error en la solicitud:", error);
    //     store.dispatch("showToast", {
    //       message: String(error.response.data.message[0]),
    //       type: "error",
    //     });
    //   });
    // },

    bajaTotal(e: any, user: any) {
      console.log("Usuario recibido:", user);
      console.log("Terminal seleccionado:", e);

      // Construcción del objeto de datos
      const data = {
        accountNumber: e.accountNumber, // Verifica que este campo exista en `terminalSelected`
        deviceId: e.id, // Verifica que este campo exista en `terminalSelected`
        userId: user.userId, // Toma el `userId` del objeto `user`
      };

      // Log para verificar los datos enviados al backend
      // console.log("Datos enviados al backend:", JSON.stringify(data));

      // Llamada a la acción de Vuex
      store
        .dispatch("DELETETERMINALDB", data)
        .then((response) => {
          // Log para verificar la respuesta del backend
          // console.log("Respuesta del backend:", response);

          // Verifica la respuesta
          if (response && response.data && response.data.isValid) {
            console.log("Baja exitosa.");
            store.dispatch("showToast", {
              message: "Baja Correcta",
              type: "success",
            });

            this.showPopupBajaValidation = true; // Muestra validación de éxito
          } else {
            console.log(
              "Error en la baja:",
              response?.data || "Sin datos en la respuesta"
            );
            store.dispatch("showToast", {
              message: "Error al dar de baja",
              type: "error",
            });
          }
        })
        .catch((error) => {
          // Log para errores
          console.error("Error en la solicitud:", error);

          // Manejo de errores
          const errorMessage = error.response?.data?.error || "Error inesperado";
          store.dispatch("showToast", {
            message: errorMessage,
            type: "error",
          });
        });
    },

    generarTicket(data: any, terminal: any) {
      let dataEmail: {
        message: any;
        subject: string;
        to?: string[];
        cc?: string[];
      } = {
        // message: `${data.descripcion}\n\n Esta Incidencia ha sido reportado por: ${this.user.email}`,
        message: `${data.descripcion} \n\n . Esta Incidencia ha sido reportada por: ${this.user.email}. \n Por favor, comuníquese con el usuario a la brevedad.`,
        subject: `${data.request.name} - ${terminal.terminals_nickname} - ${terminal.kitSerialNumber}`,
        cc: [this.user.email, "orderingmn.pe@telefonica.com"],
      };

      switch (terminal.serviceplans_accountNumber) {
        case "ACC-2107386-24903-10":
          dataEmail.to = ["nocmnla.fija.pe@telefonica.com"];
          // dataEmail.to = ["elvis.shahuano@developers-peru.com"]
          break;

        case "ACC-2572780-53197-21":
          dataEmail.to = ["nocmnla.fija.pe@telefonica.com"];
          break;

        case "ACC-2623501-68947-18":
          dataEmail.to = [
            "fernando.garciamartin@telefonica.com",
            "jorge.martinezmartin@telefonica.com",
            "soporte.vsat.globalsolutions@telefonica.com",
          ];
          break;

        case "ACC-2623730-80304-3":
          dataEmail.to = [
            "servicedesk.br.globalsolutions@telefonica.com",
            "eduardo.panis@telefonica.com",
          ];
          break;

        case "ACC-2698122-83463-19":
          dataEmail.to = ["nocmnla.fija.pe@telefonica.com"];
          break;

        case "ACC-2749591-97120-21":
          dataEmail.to = ["nocmnla.fija.pe@telefonica.com"];
          break;

        case "ACC-2750974-27074-19":
          dataEmail.to = ["nocmnla.fija.pe@telefonica.com"];
          break;

        case "ACC-2867803-78185-28":
          dataEmail.to = [
            "fernando.garciamartin@telefonica.com",
            "jorge.martinezmartin@telefonica.com",
            "soporte.vsat.globalsolutions@telefonica.com",
          ];
          break;

        case "ACC-2912340-58640-9":
          dataEmail.to = [
            "fernando.garciamartin@telefonica.com",
            "jorge.martinezmartin@telefonica.com",
            "soporte.vsat.globalsolutions@telefonica.com",
          ];
          break;

        default:
          break;
      }

      store
        .dispatch("SEND_MAIL_NEST", dataEmail)
        .then((response) => {
          this.showPopupDrowSoporte = false;
          store.dispatch("showToast", {
            message: "SE ENVIO CON EXITO EL EMAIL",
            type: "success",
          });
        })
        .catch((error) => {
          console.log("error", error);
        });

      let created = {
        tableReference: "starlink",
        referenceId: terminal.terminals_id,
        pinProblemId: data.request.id,
        description: data.descripcion,
        contactIncidence: this.user.fullname,
        mediaComunicationId: data.mediaComunication.id,
        dataComunication: data.dataComunication,
        typeContactId: data.typeContact.id,
        platform: 127,
      };
      store
        .dispatch("CREATEDSUPPORT", created)
        .then((response) => {
          store.dispatch("showToast", {
            message: "SE ENVIO CON EXITO SU CONSULTA A SOPORTE",
            type: "success",
          });
          // this.$router.push("/home");
          this.showPopupDrowSoporte = false;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    closePopup() {
      // if (!event.target.closest(".popup-form")) {
      this.showPopup = false;
      this.showPopupDrow = false;
      this.showPopupDrowSus = false;
      this.showPopupDrowBaja = false;
      this.showPopupDrowSoporte = false;
      this.showPopupAddress = false;
      this.showPopupDrowComisionamiento = false;
      this.showPopupBajaValidation = false;
      this.showPopupCambiarSiteid = false;
      this.showPopupReactivar = false;
      this.showPopupCambiarBypass = false;
      this.showPopupReinicioRemoto = false;
      this.showPopupAgregarKit = false;
      this.showPopupCambiarKit = false;
      this.showPopupReasignacionPrincipal = false;
      this.showPopupServicioHorario = false;
      this.showPopupDNS = false;
      this.showPopupRenombrarWifi = false;
      this.showPopupCambiarPasswordWifi = false;
      this.showPopupDesactivarDatosPriory = false;
      this.showPopupActivarDatosPriory = false;
      this.showPopupReiniciarTerminal = false;
      this.showPopupPerfilTabla = false;
      this.terminalSelected = null;
      this.showPopupPrincipal = false;
      // }
    },
    confirmPopupPrincipal() {
      console.log("entro");
      this.showPopupPrincipal = false;
      setTimeout(() => {
        this.showPopup = true;
      }, 1000); // Ajusta el tiempo de espera según sea necesario
    },
    confirmSubmitSuspender(event: any) {
      event.preventDefault();
      const confirmMessage = "¿Estás seguro de Suspender?";
      if (window.confirm(confirmMessage)) {
        // Aquí puedes realizar las acciones de envío del formulario
        console.log("Formulario enviado");
      }
    },
    confirmSubmitBaja(event: any) {
      event.preventDefault();
      const confirmMessage = "¿Estás seguro de Baja Total?";
      if (window.confirm(confirmMessage)) {
        // Aquí puedes realizar las acciones de envío del formulario
        console.log("Formulario enviado");
      }
    },
    confirmSubmitSoporte(event: any) {
      event.preventDefault();
      const confirmMessage = "¿Estás seguro de enviar tu Consulta?";
      if (window.confirm(confirmMessage)) {
        // Aquí puedes realizar las acciones de envío del formulario
        console.log("Formulario enviado");
      }
    },
    confirmSubmitComisionamiento(event: any) {
      event.preventDefault();
      const confirmMessage = "¿Estás seguro de enviar tu Consulta?";
      if (window.confirm(confirmMessage)) {
        // Aquí puedes realizar las acciones de envío del formulario
        console.log("Formulario enviado");
      }
    },
    terminalSelect(value: any) {
      this.terminalSelected = value;
      if (this.showPopupDrowSoporte) {
        this.handleSupportButtonClick();
      }

      if (this.showPopupDrowComisionamiento) {
        this.selectComisionamiento();
      }
    },
    verifiPoppup() {
      this.showPopupPrincipal = true;
    },

    selectComisionamiento() {
      const terminalId = (this.terminalSelected as any)?.id || 0;

      const data = {
        endpoint: 79,
        principalId: this.user.principalId,
        platform: "starlink",
        roleId: this.user.roleId,
        userId: this.user.id,
        orgId: this.user.orgId,
        xparams: {
          id: terminalId,
        },
      };

      store
        .dispatch("SERVICELINEUSERCONFIG", data)
        .then((response) => {
          // Asegúrate de que la respuesta contenga datos y filtra por terminalId
          if (response.data && response.data.data) {
            // Filtra los datos recibidos por terminalId
            this.commisioning = response.data.data.filter(
              (item: CommisioningItem) => item.terminalId === terminalId
            );
          } else {
            this.commisioning = [];
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    },

    downloadFile(fileData: CommisioningItem) {
      const data = {
        platform: 1,
        principalOrgId: 1,
        user_id: 1,
        super_grand: 1,
        xparams: {
          bucketName: fileData.bucket,
          folderName: fileData.folder,
          fileName: fileData.name,
        },
      };

      store
        .dispatch("DOWNLOADFILE", data)
        .then((response) => {
          // Aquí se asume que response.data contiene el arrayBuffer del archivo
          const arrayBuffer = response.data;

          // Crear un Blob a partir del arrayBuffer recibido
          const blob = new Blob([arrayBuffer]);

          // Crear un objeto URL y descargar el archivo mediante un enlace invisible
          const downloadUrl = URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = downloadUrl;
          a.download = fileData.name; // Nombre del archivo que se descargará
          document.body.appendChild(a);
          a.click();

          // Limpiar el objeto URL creado
          URL.revokeObjectURL(downloadUrl);
        })
        .catch((error) => {
          console.error("Error al descargar el archivo:", error);
        });
    },

    handleSupportButtonClick() {
      this.ticketSoporte.typeContact = this.problemtypes.dataTypeContactId[0]; // Asigna la primera opción
      this.ticketSoporte.dataTypeContact = this.user.fullname;
      this.ticketSoporte.dataComunication = this.user.email;
      this.ticketSoporte.mediaComunication = this.problemtypes.dataMediaComunicationId[0]; // Asigna la primera opción
    },

    validUpdate(terminal: any) {
      if (terminal.st_relationships_v2_created_at) {
        let now = new Date();
        let dateUpdate = new Date(terminal.st_relationships_v2_created_at);
        let diferencial = dateUpdate.valueOf() - now.valueOf();
        let diffDays = Math.floor(diferencial / 86400000);
        if (diffDays <= 0) {
          this.showPopupDrow = true;
          this.serviceplanByTerminal(terminal);
        } else {
          store.dispatch("showToast", {
            message: "Tienes un cambio de Plan pendiente el " + dateUpdate,
            type: "error",
          });
        }
      } else {
        this.showPopupDrow = true;
        this.serviceplanByTerminal(terminal);
      }
    },
  },
});
</script>
<style lang="scss">
@import "vue-select/dist/vue-select.css";

.table-content {
  display: flex;
  align-items: flex-start !important;
  justify-content: center;
  flex-direction: column;
  padding-top: 127px;
  margin-bottom: 100px;
  max-width: 1630px;

  .buscar-content {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;
    max-width: 1630px;
  }
}

.menu-button {
  background-color: #15059088;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;

  img {
    width: 20px;
    height: 20px;
  }
}

.title-c {
  span {
    color: #000000 !important;
  }
}

.header-content {
  display: flex;
  justify-content: center;
  // flex-direction: row-reverse;
}

.header-close {
  display: flex;
  justify-content: flex-end;
}

.btn-inactive {
  border: none;
  background-color: #aaaaaa;
  border-radius: 9px;
  color: #ffffff;
  font-weight: bold;
  padding: 10px;
}

.btn-active {
  border: none;
  background-color: #22bf03;
  border-radius: 9px;
  color: #ffffff;
  font-weight: bold;
  padding: 10px;
}

.popup-form .group input {
  width: 100%;
  max-width: 320px;
}

.flexing-menu {
  display: flex;
  justify-content: space-between;
  width: 100%;

  img {
    flex-basis: 10%;
    display: flex;
    justify-content: center;
  }

  span {
    flex-basis: calc(90% - 5px);
    margin-left: 5px;
  }
}

.file-upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.file-upload-label,
.upload-button {
  cursor: pointer;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  text-align: center;
}

.file-upload-label:hover,
.upload-button:hover {
  opacity: 0.8;
}

.file-upload-input {
  display: none;
}

.btn-content {
  margin-left: 10px;

  button {
    height: 40px;
    cursor: pointer;
    width: 100%;
    border-radius: 9px;
    background-color: #007bff;
    color: #ffffff;
  }
}

.p-column-title {
  color: #000000;
}

.name-column {
  cursor: pointer;
}

// body {
//   margin: 0;
//   font-family: 'Arial', sans-serif;
// }

.slider-container {
  overflow: hidden;
  width: 103%;
  /* Ajusta el ancho del contenedor del slider según tus necesidades */
  margin: auto;
  /* Centra el contenedor */
}

.slider {
  display: flex;
  animation: slide 10s linear infinite;
}

.message {
  flex: 0 0 38%;
  /* Ajusta el ancho de cada mensaje según tus necesidades */
  padding: 10px;
  text-align: center;
  box-sizing: border-box;
  /* Asegura que el padding no afecte el tamaño total */
}

// .success {
//   // background-color: #4caf50;
//   // color: #fff;
// }

@keyframes slide {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-38%);
    /* Ajusta el valor para que coincida con el ancho de cada mensaje */
  }
}

button {
  font-weight: bold !important;
}

li {
  font-weight: bold !important;
}

.p-dropdown-item-label {
  color: #000000 !important;
}

.p-inputtext {
  color: #000000 !important;
}

.spinner-content {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 1300px;
  min-height: 600px;
}

.spinner-content2 {
  display: flex;
  justify-content: center;
  align-items: center;
  // min-width: 1300px;
  // min-height: 600px;
}

.download-link {
  cursor: pointer;
  text-decoration: underline;
  color: blue;
}

.download-link:hover {
  color: darkblue;
}
</style>
